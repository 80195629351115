import React, { HTMLAttributes, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLocale } from '../../../../../../core/i18n/Locale';
import { useTrackEventAmplitude } from '../../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../../design-system/components/form/button/Button';
import { IconArrowRight } from '../../../../../../design-system/components/icons/ArrowRight';
import {
    NavItemButton,
    NavItemLink,
} from '../../../../../../design-system/components/nav/nav-item/NavItem';
import { useFeatureFlag } from '../../../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../../../shared/hooks/useUrlGenerator';
import {
    SUMMER_SLUG_KEYWORD_BY_MARKET,
    ThematicItemSummerCampaign,
} from '../../../Shared/WhereToGo/thematicItem/SummerCampaignItem/SummerCampaignItem';
import {
    ThematicItemWinterCampaign,
    WINTER_SLUG_KEYWORD_BY_MARKET,
} from '../../../Shared/WhereToGo/thematicItem/WinterCampaignItem/WinterCampaignItem';
import {
    PrimaryNavValue,
    WhereToGoDropdown,
    WhereToGoDropdownThematic,
    WhereToGoThematicValue,
} from '../../../types';

import { MobileMenuList } from '../../MenuList/MobileMenuList';
import { MobileSubNav } from '../MobileSubNav/MobileSubNav';

import { MobileView } from '../MobileView';

import style from './WhereToGoMobileView.module.css';

type Props = {
    onNavValueChange: (value: PrimaryNavValue) => void;
    whereToGoDropdown: WhereToGoDropdown;
};

export function WhereToGoMobileView({ onNavValueChange, whereToGoDropdown }: Props) {
    const { formatMessage } = useIntl();
    const [currentThematicCategory, setCurrentThematicCategory] =
        useState<WhereToGoThematicValue>(null);

    const whereToGoThematicTitles = {
        type: formatMessage({
            id: 'global.header.globalNav.whereToGo.thematicType',
        }),
        what: formatMessage({
            id: 'global.header.globalNav.whereToGo.thematicWhat',
        }),
        when: formatMessage({
            id: 'global.header.globalNav.whereToGo.thematicWhen',
        }),
        who: formatMessage({
            id: 'global.header.globalNav.whereToGo.thematicWho',
        }),
    };

    const whereToGoTitle = currentThematicCategory
        ? whereToGoThematicTitles[currentThematicCategory]
        : formatMessage({
              id: 'global.header.globalNav.whereToGo.label',
          });

    return (
        <MobileView>
            <MobileSubNav
                title={whereToGoTitle}
                showPreviousButton={true}
                onClose={() => {
                    onNavValueChange(null);
                    setCurrentThematicCategory(null);
                }}
                onPrevious={() => {
                    onNavValueChange(currentThematicCategory ? 'WHERE_TO_GO' : 'MOBILE_MENU');
                    setCurrentThematicCategory(null);
                }}
            />
            {currentThematicCategory === null ? (
                <WhereToGoNav
                    onThematicCategoryChange={(thematic) => {
                        setCurrentThematicCategory(thematic);
                    }}
                    travelTypes={whereToGoDropdown.travelTypes}
                    what={whereToGoDropdown.what}
                    when={whereToGoDropdown.when}
                    who={whereToGoDropdown.who}
                />
            ) : (
                <ThematicCategoryNav
                    currentThematicCategory={currentThematicCategory}
                    travelTypes={whereToGoDropdown.travelTypes}
                    what={whereToGoDropdown.what}
                    when={whereToGoDropdown.when}
                    who={whereToGoDropdown.who}
                />
            )}
        </MobileView>
    );
}

type WhereToGoNavProps = HTMLAttributes<HTMLElement> & {
    who: WhereToGoDropdownThematic[];
    what: WhereToGoDropdownThematic[];
    when: WhereToGoDropdownThematic[];
    travelTypes: WhereToGoDropdownThematic[];
    onThematicCategoryChange: (thematic: WhereToGoThematicValue) => void;
};

function WhereToGoNav({
    who,
    what,
    when,
    travelTypes,
    onThematicCategoryChange,
    ...props
}: WhereToGoNavProps) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();

    return (
        <>
            <MobileMenuList {...props}>
                {who.length > 0 ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('who');
                        }}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWho',
                        })}
                    </NavItemButton>
                ) : null}

                {what.length > 0 ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('what');
                        }}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWhat',
                        })}
                    </NavItemButton>
                ) : null}

                {when.length ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('when');
                        }}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWhen',
                        })}
                    </NavItemButton>
                ) : null}

                {travelTypes.length > 0 ? (
                    <NavItemButton
                        iconTrailing={<IconArrowRight />}
                        onClick={() => {
                            onThematicCategoryChange('type');
                        }}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicType',
                        })}
                    </NavItemButton>
                ) : null}
            </MobileMenuList>
            <div className={style.footer}>
                <Button priority="secondary" reversed href={generatePath('where_to_go')}>
                    {formatMessage({
                        id: 'global.header.globalNav.whereToGo.cta',
                    })}
                </Button>
            </div>
        </>
    );
}

type ThematicCategoryNavProps = {
    currentThematicCategory: WhereToGoThematicValue;
    travelTypes: WhereToGoDropdownThematic[];
    what: WhereToGoDropdownThematic[];
    when: WhereToGoDropdownThematic[];
    who: WhereToGoDropdownThematic[];
};

function ThematicCategoryNav({
    currentThematicCategory,
    travelTypes,
    what,
    when,
    who,
}: ThematicCategoryNavProps) {
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <MobileMenuList>
            {currentThematicCategory === 'who' ? (
                <ThematicList
                    onThematicClick={(thematic) => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.headerWhoThematicClicked({
                                ...defaultProperties,
                                block: 'header',
                                thematic_slug: thematic.slug,
                            });
                        });
                    }}
                    thematics={who}
                />
            ) : null}

            {currentThematicCategory === 'what' ? (
                <ThematicList
                    onThematicClick={(thematic) => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.headerWhatThematicClicked({
                                ...defaultProperties,
                                block: 'header',
                                thematic_slug: thematic.slug,
                            });
                        });
                    }}
                    thematics={what}
                />
            ) : null}

            {currentThematicCategory === 'when' ? (
                <ThematicList
                    onThematicClick={(thematic) => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.headerWhenThematicClicked({
                                ...defaultProperties,
                                block: 'header',
                                thematic_slug: thematic.slug,
                            });
                        });
                    }}
                    thematics={when}
                />
            ) : null}

            {currentThematicCategory === 'type' ? (
                <ThematicList
                    onThematicClick={(thematic) => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.headerHowThematicClicked({
                                ...defaultProperties,
                                block: 'header',
                                thematic_slug: thematic.slug,
                            });
                        });
                    }}
                    thematics={travelTypes}
                />
            ) : null}
        </MobileMenuList>
    );
}

type ThematicListProps = {
    thematics: WhereToGoDropdownThematic[];
    onThematicClick: (thematic: WhereToGoDropdownThematic) => void;
};

function ThematicList({ thematics, onThematicClick }: ThematicListProps) {
    const { locale } = useLocale();
    const { hasFeature } = useFeatureFlag();

    return (
        <div>
            {thematics.map((thematic) => {
                const isWinterHighlight =
                    hasFeature('marketing_campaign_winter') &&
                    thematic.slug === WINTER_SLUG_KEYWORD_BY_MARKET[locale];

                const isSummerHighlight =
                    hasFeature('marketing_campaign_summer') &&
                    thematic.slug === SUMMER_SLUG_KEYWORD_BY_MARKET[locale];

                if (isWinterHighlight) {
                    return (
                        <ThematicItemWinterCampaign
                            key={thematic.slug}
                            thematic={thematic}
                            onThematicClick={onThematicClick}
                        />
                    );
                }

                if (isSummerHighlight) {
                    return (
                        <ThematicItemSummerCampaign
                            key={thematic.slug}
                            thematic={thematic}
                            onThematicClick={onThematicClick}
                        />
                    );
                }

                return (
                    <NavItemLink
                        key={thematic.slug}
                        href={thematic.url}
                        onClick={() => {
                            onThematicClick(thematic);
                        }}
                    >
                        {thematic.label}
                    </NavItemLink>
                );
            })}
        </div>
    );
}
