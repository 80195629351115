'use client';

import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { MapProvider } from '../../components/Map/MapProvider';
import { Marketing } from '../../components/Marketing/Marketing';
import { ModalNewsLetter } from '../../components/Newsletter/ModalNewsletter/ModalNewsletter';
import { DynamicSiteSuggestionModal } from '../../components/SiteSuggestionModal/SiteSuggestionModal.dynamic';
import { useSiteSuggestionModal } from '../../components/SiteSuggestionModal/useSiteSuggestionModal';
import { Locale } from '../../core/i18n/Locale';
import { TranslationMessages } from '../../core/i18n/Messages';
import { logger } from '../../core/Log/logger';
import { AmplitudeProvider } from '../../core/Tracking/Amplitude/Amplitude';
import { GlobalSetup } from '../../domains/GlobalSetup/GlobalSetup';
import { GoogleOneTap } from '../../domains/Login/Provider/Google/GoogleOneTap';
import { DidomiScript } from '../utilities/GDPR/DidomiScript';
import { GTMScript } from '../utilities/GTM/GTMScript';

import { CurrencyProvider } from './currency/CurrencyProvider';
import { CurrentSiteProvider } from './currentSite/CurrentSiteProvider';
import { PageProvider } from './page/PageProvider';
import { UserProvider } from './user/UserProvider';

type Props = {
    locale: Locale;
    defaultLocale: Locale;
    children: ReactNode;
    seoContext: string;
    isConnectedOnLoad: boolean;
    intlMessages: TranslationMessages;
    mapToken?: string;
    gtmId: string;
    gtmData: Record<string, unknown>;
    enableGoogleOneTap?: boolean;
    newPrimaryNav: boolean;
};

export function AppProviders({
    children,
    locale,
    defaultLocale,
    seoContext,
    isConnectedOnLoad,
    intlMessages,
    mapToken,
    gtmId,
    gtmData,
    enableGoogleOneTap,
    newPrimaryNav,
}: Props) {
    return (
        <CurrentSiteProvider locale={locale}>
            <PageProvider seoContext={seoContext} newPrimaryNavEnabled={newPrimaryNav}>
                <UserProvider isConnectedOnLoad={isConnectedOnLoad}>
                    <AmplitudeProvider isConnectedOnLoad={isConnectedOnLoad}>
                        <CurrencyProvider>
                            <IntlProvider
                                defaultLocale={defaultLocale}
                                locale={locale}
                                messages={intlMessages}
                                onError={(error) => {
                                    logger.error(
                                        'IntlProvider error',
                                        {
                                            locale,
                                            defaultLocale,
                                        },
                                        error,
                                    );
                                }}
                            >
                                <MapProvider token={mapToken}>
                                    <GlobalSetup />
                                    <DidomiScript />
                                    <GTMScript gtmId={gtmId} gtmData={gtmData} />
                                    <div id="modal-wrapper" />
                                    <ModalNewsLetter />
                                    <SiteSuggestionModal />
                                    <Marketing />
                                    {enableGoogleOneTap ? <GoogleOneTap /> : null}
                                    {children}
                                </MapProvider>
                            </IntlProvider>
                        </CurrencyProvider>
                    </AmplitudeProvider>
                </UserProvider>
            </PageProvider>
        </CurrentSiteProvider>
    );
}

function SiteSuggestionModal() {
    const siteSuggestion = useSiteSuggestionModal();

    return siteSuggestion ? <DynamicSiteSuggestionModal /> : null;
}
