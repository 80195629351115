import Image from 'next/image';

import React from 'react';

import { Locale } from '../../../../../../../core/i18n/Locale';
import { IconArrowRight } from '../../../../../../../design-system/components/icons/ArrowRight';
import { imageLoaderForPath } from '../../../../../Image/URL/ImageURL';

import { WhereToGoDropdownThematic } from '../../../../types';

import style from './SummerCampaignItem.module.css';

export const SUMMER_SLUG_KEYWORD_BY_MARKET: Record<Locale, string> = {
    'de-CH': 'sommer-reisen',
    'de-DE': 'sommerurlaub',
    'en-CA': '',
    'en-GB': '',
    'en-US': '',
    'es-ES': 'viajar-este-verano',
    'fr-FR': 'voyage-ete',
    'it-IT': 'vacanze-estive',
    'nl-NL': 'zomerreis',
};
type ThematicItemSummerCampaignProps = {
    thematic: WhereToGoDropdownThematic;
    onThematicClick: (thematic: WhereToGoDropdownThematic) => void;
};

export function ThematicItemSummerCampaign({
    thematic,
    onThematicClick,
}: ThematicItemSummerCampaignProps) {
    return (
        <li className={style.thematicListItemSummerCampaignWrapper}>
            <a
                href={thematic.url}
                onClick={() => {
                    onThematicClick(thematic);
                }}
                className={style.thematicListItemSummerCampaignContainer}
            >
                <Image
                    className={style.thematicListItemSummerCampaignContainerImage}
                    alt="Summer campaign"
                    src="/campaign/summer/summer-background-v2.jpg"
                    loader={imageLoaderForPath}
                    fill
                />
                <div className={style.thematicListItemSummerCampaignLabel}>
                    <div className="font-xs-bold">{thematic.label}</div>
                    <IconArrowRight className={style.thematicListItemSummerCampaignIcon} />
                </div>
            </a>
        </li>
    );
}
