import { ImageProps } from 'next/image';
import { HTMLAttributeAnchorTarget, MouseEvent } from 'react';

import { SquareCard } from '../square/SquareCard';

interface PrimaryNavDestinationCardProps {
    href: string;
    image: ImageProps;
    target?: HTMLAttributeAnchorTarget;
    title: string;
    small?: boolean;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

export function PrimaryNavDestinationCard(props: PrimaryNavDestinationCardProps) {
    return <SquareCard {...props} />;
}

PrimaryNavDestinationCard.PICTURE_RATIO = SquareCard.PICTURE_RATIO;
