'use client';

import { useCallback, useEffect } from 'react';

import { trackOptin } from '../../../../components/Newsletter/NewsletterService';
import { logger } from '../../../../core/Log/logger';

import {
    useAmplitude,
    useTrackEventAmplitude,
} from '../../../../core/Tracking/Amplitude/Amplitude';
import { useUser } from '../../../../shared/providers/user/useUser';
import { RegisterOptions } from '../../Login';
import { SocialLoginProviderName } from '../Provider';

import { useGoogleScript, WindowGoogle } from './Google';

export function GoogleOneTap() {
    const { isUserFetched, isUserConnected } = useUser();
    const { setIsConnected } = useAmplitude();
    const { trackEvent } = useTrackEventAmplitude();
    const userNotConnected = isUserFetched && !isUserConnected;

    const onRegister = useCallback(
        (loginProviderName: SocialLoginProviderName, options?: RegisterOptions) => {
            void trackOptin({
                isNew: true,
                subscribeMode: '',
                subscribeModeDetail: '',
                email: options?.email ?? '',
            });

            trackEvent((ampli, defaultProperties) => {
                ampli.authenticationSucceed({
                    ...defaultProperties,
                    already_existing: false,
                    connection_source: 'google-one-tap',
                });
            });
        },
        [trackEvent],
    );

    const onLogin = useCallback(() => {
        trackEvent((ampli, defaultProperties) => {
            ampli.authenticationSucceed({
                ...defaultProperties,
                already_existing: true,
                connection_source: 'google-one-tap',
            });
        });

        setIsConnected(true);
    }, [setIsConnected, trackEvent]);

    const { load, isLoaded: googleScriptLoaded } = useGoogleScript(
        'google-one-tap',
        onLogin,
        undefined,
        onRegister,
    );

    useEffect(() => {
        if (userNotConnected) {
            load().catch((error) => {
                logger.error('Google one tap could not init', {}, error);
            });
        }
    }, [load, userNotConnected]);

    useEffect(() => {
        if (userNotConnected && googleScriptLoaded && window.google) {
            (window.google as WindowGoogle).accounts.id.prompt();
        }
    }, [googleScriptLoaded, userNotConnected]);

    return null;
}
