import React, { HTMLAttributes, RefObject } from 'react';

import { Container } from '../../../Container/Container';
import { DestinationsContent } from '../../Shared/Destinations/DestinationsContent';
import {
    DestinationsDropdownContextContinent,
    DestinationsDropdownContinent,
    DestinationsDropdownWorld,
} from '../../types';
import { DropdownContent } from '../DropdownContent/DropdownContents';

type DestinationDropdownProps = HTMLAttributes<HTMLDivElement> & {
    continents: DestinationsDropdownContinent[];
    contextContinent: DestinationsDropdownContextContinent;
    onClickOutside: () => void;
    triggerRef: RefObject<HTMLElement | null>;
    world: DestinationsDropdownWorld;
};

export function DestinationsDropdownContent({
    continents,
    contextContinent,
    world,
    ...props
}: DestinationDropdownProps) {
    return (
        <DropdownContent {...props}>
            <Container>
                <DestinationsContent
                    continents={continents}
                    contextContinent={contextContinent}
                    world={world}
                />
            </Container>
        </DropdownContent>
    );
}
