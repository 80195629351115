import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './Container.module.css';

type Props = HTMLAttributes<HTMLDivElement>;

export function Container(props: Props) {
    const { className, ...restProps } = props;

    return (
        <div {...restProps} className={classNames(styles.container, className)}>
            {props.children}
        </div>
    );
}
