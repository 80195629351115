'use client';

import { useEffect, useState } from 'react';

import { Modal } from '../../../design-system/components/modal/Modal';
import { useModal } from '../../../design-system/components/modal/useModal';
import { imageLoaderForPath } from '../../../domains/Layout/Image/URL/ImageURL';
import { AuthTrackingContext } from '../../../domains/Login/Login';
import { useUser } from '../../../shared/providers/user/useUser';
import { Newsletter } from '../Newsletter';
import { AuthTrackingContextToSubscribeMod } from '../NewsletterService';

export function ModalNewsLetter() {
    const modalProps = useModal();
    const [context, setContext] = useState<AuthTrackingContext>('default');
    const { user } = useUser();
    const subscribeMode = AuthTrackingContextToSubscribeMod(context);

    useEffect(() => {
        const onRegister = (event: Event) => {
            if (event instanceof CustomEvent) {
                const { context } = event.detail;
                if (context) {
                    setContext(context as AuthTrackingContext);
                }
            }
            modalProps.open();
        };

        document.addEventListener('social-login-register', onRegister);

        return () => {
            document.removeEventListener('social-login-register', onRegister);
        };
    }, [modalProps]);

    if (!modalProps.isOpen || null === user) {
        return null;
    }

    return (
        <Modal
            {...modalProps}
            headerVariant="image"
            image={{
                src: '/newsletter/newsletter-mosaique.jpg',
                alt: '',
                loader: imageLoaderForPath,
                height: 233,
                width: 350,
            }}
            fit="contain"
        >
            <Newsletter
                subscribeMode={subscribeMode}
                acquisitionTags={[`${subscribeMode}_post-social-login-register`]}
                trackingInformation={{ subscription: { block: 'popin_newsletter' } }}
            />
        </Modal>
    );
}
