import { useCallback } from 'react';

import { ampli } from '../../core/Tracking/Amplitude/__AUTO_GENERATED__';
import {
    TrackingProperties,
    useTrackEventAmplitude,
} from '../../core/Tracking/Amplitude/Amplitude';

import { addUserAndSilverpop } from './NewsletterService';

type SendProps = {
    email: string;
    subscribeMode: number;
    subscribeModeDetail?: string;
    acquisitionTags: string[];
    confirmed: boolean;
    destinationSlug?: string;
    requestPath?: string;
    trackingInformation: {
        subscription: TrackingProperties<typeof ampli.newsletterSubscribed>;
    };
};

export function useNewsletter() {
    const { trackEvent } = useTrackEventAmplitude();

    const send = useCallback(
        ({
            email,
            subscribeMode,
            subscribeModeDetail,
            acquisitionTags,
            confirmed,
            destinationSlug,
            requestPath,
            trackingInformation,
        }: SendProps) => {
            const sender = addUserAndSilverpop(
                {
                    email,
                    subscribe_mode: subscribeMode,
                    subscribe_mode_detail: subscribeModeDetail || null,
                    acquisition_tags: acquisitionTags,
                    confirm: confirmed,
                    destination_slug: destinationSlug,
                },
                {
                    requestPath,
                },
            );

            sender.getCampaignFromCookies();

            return sender.send().then(() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.newsletterSubscribed({
                        ...defaultProperties,
                        ...trackingInformation.subscription,
                    });
                });
            });
        },
        [trackEvent],
    );

    return {
        send,
    };
}
