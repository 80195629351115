import { useIntl } from 'react-intl';

import { NavItemLink } from '../../../../../../design-system/components/nav/nav-item/NavItem';
import { usePrimaryNavCommitmentsLinks } from '../../../Shared/Commitments/usePrimaryNavCommitmentsLinks';
import { PrimaryNavValue } from '../../../types';

import { MobileMenuList } from '../../MenuList/MobileMenuList';
import { MobileSubNav } from '../MobileSubNav/MobileSubNav';
import { MobileView } from '../MobileView';

type Props = {
    onNavValueChange: (value: PrimaryNavValue) => void;
};

export function CommitmentsMobileView({ onNavValueChange }: Props) {
    const { formatMessage } = useIntl();
    const commitmentsLinks = usePrimaryNavCommitmentsLinks();

    return (
        <MobileView>
            <MobileSubNav
                title={formatMessage({
                    id: 'global.header.globalNav.commitments.label',
                })}
                showPreviousButton={true}
                onClose={() => {
                    onNavValueChange(null);
                }}
                onPrevious={() => {
                    onNavValueChange('MOBILE_MENU');
                }}
            />
            <MobileMenuList>
                {commitmentsLinks.map((link) => {
                    return (
                        <NavItemLink
                            key={link.name}
                            href={link.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                link.trackClick();
                            }}
                        >
                            {link.label}
                        </NavItemLink>
                    );
                })}
            </MobileMenuList>
        </MobileView>
    );
}
