import { SVGAttributes } from 'react';

export function EvaneosLogo({
    cut = false,
    ...props
}: SVGAttributes<SVGElement> & { cut?: boolean }) {
    return (
        <svg
            viewBox={cut ? '0 0 360 39' : '0 0 360 52'}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            role="img"
            {...props}
        >
            <title>Evaneos</title>
            <path d="M0.000748807 25.8436C0.000748807 10.139 11.4254 0.346413 25.1898 0.346413C37.7343 0.346413 45.5867 8.50691 45.9947 19.5192L46.0945 24.0075H15.9094C16.8296 34.4078 22.8458 37.876 30.5939 37.876C37.2221 37.876 43.6464 35.328 46.6024 29.3075C45.1743 45.0121 34.8738 50.9284 24.1654 50.9284C9.79334 50.9284 -0.099087 40.5282 0.000748807 25.8436ZM31.7182 17.5833C31.7182 11.1591 29.7779 7.38701 24.8859 7.38701C19.994 7.38701 16.8296 10.9551 16.0135 17.5833H31.7182Z" />
            <path d="M44.5709 1.36647H70.4718C66.3916 3.61061 66.9038 5.54655 68.5315 8.91493L76.7918 25.0276L83.6241 11.3631C86.2762 6.05875 85.356 3.41093 81.2801 1.37081H101.165C97.9006 3.61495 96.2685 7.08316 94.0244 11.2632L75.5678 45.6285C74.3437 47.8726 73.5277 49.6045 72.8158 51.6447L50.0793 9.21877C48.4472 6.15859 46.8151 3.40659 44.5709 1.36647Z" />
            <path d="M99.3374 37.0599C99.3374 27.9836 104.946 22.9874 117.49 20.0314L125.238 18.1953C128.199 17.4834 129.319 16.4634 129.319 14.0152C129.319 10.751 127.482 8.71092 123.198 8.71092C117.182 8.71092 106.981 11.8709 102.801 21.4595V0.346413C104.025 2.2867 105.657 3.09841 107.797 3.09841C111.773 3.09841 119.526 0.346413 127.582 0.346413C138.903 0.346413 145.427 5.54656 145.427 16.255V39.5081C145.427 45.0164 146.955 48.4803 149.507 49.9084H129.319V36.1397C127.378 46.8482 120.65 50.9284 113.31 50.9284C105.558 50.9284 99.3374 46.5443 99.3374 37.0599ZM120.446 40.3241C125.85 40.3241 129.319 35.5277 129.319 29.6157V21.8675C128.706 23.1915 126.87 24.0075 124.934 24.7237L120.346 26.3558C116.674 27.6841 114.33 30.028 114.33 33.9043C114.33 38.4924 116.878 40.3285 120.451 40.3285L120.446 40.3241Z" />
            <path d="M155.124 39.5081V11.7711C155.124 6.26277 153.596 2.7989 151.044 1.37081H171.645V14.1151C173.277 4.42666 178.069 0.346413 186.533 0.346413C196.938 0.346413 202.75 6.56662 202.75 19.3152V39.5081C202.75 45.0164 204.278 48.4803 206.826 49.9084H182.149C184.701 48.4803 186.229 45.0121 186.229 39.5081V19.2154C186.229 13.707 183.781 11.5671 178.889 11.5671C174.401 11.5671 171.649 13.299 171.649 19.2154V39.5081C171.649 45.0164 173.177 48.4803 175.729 49.9084H151.052C153.6 48.4803 155.133 45.0121 155.133 39.5081H155.124Z" />
            <path d="M209.278 25.8436C209.278 10.139 220.699 0.346413 234.467 0.346413C247.012 0.346413 254.864 8.50691 255.272 19.5192L255.372 24.0075H225.187C226.107 34.4078 232.123 37.876 239.872 37.876C246.5 37.876 252.924 35.328 255.88 29.3075C254.452 45.0121 244.151 50.9284 233.443 50.9284C219.062 50.9284 209.17 40.5282 209.274 25.8436H209.278ZM240.996 17.5833C240.996 11.1591 239.055 7.38701 234.164 7.38701C229.272 7.38701 226.107 10.9551 225.291 17.5833H240.996Z" />
            <path d="M259.452 25.6396C259.452 10.4429 269.649 0.350754 285.661 0.350754C301.674 0.350754 311.87 10.4472 311.87 25.6396C311.87 40.832 301.674 50.9328 285.661 50.9328C269.649 50.9328 259.452 40.8363 259.452 25.6396ZM285.661 42.5683C291.986 42.5683 294.33 38.08 294.33 25.6396C294.33 13.1992 291.986 8.71092 285.661 8.71092C279.337 8.71092 276.993 13.095 276.993 25.6396C276.993 38.1842 279.337 42.5683 285.661 42.5683Z" />
            <path d="M337.98 42.9763C343.18 42.9763 345.424 40.8363 345.424 37.672C345.424 35.0198 343.896 33.9998 340.224 33.1837L332.675 31.4518C323.295 29.3118 316.867 24.3113 316.867 15.8513C316.867 6.77497 323.903 0.350751 336.039 0.350751C343.18 0.350751 347.868 2.59489 350.62 2.59489C352.56 2.59489 354.292 2.18686 355.516 0.250916V18.1953C352.456 11.9751 345.216 7.89487 337.771 7.89487C333.283 7.89487 331.347 9.6268 331.347 12.279C331.347 14.9311 333.083 16.051 337.771 17.0711L345.111 18.7032C354.292 20.7433 360 25.5354 360 33.8999C360 43.8922 351.844 50.9328 339.403 50.9328C331.651 50.9328 326.759 48.1808 322.783 48.1808C320.435 48.1808 318.703 48.8927 316.867 50.9328V28.6998C319.315 36.2439 328.699 42.9763 337.98 42.9763Z" />
        </svg>
    );
}
