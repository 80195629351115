import { FeatureFlagName, hasFeature } from '../../core/FeatureFlag/FeatureFlag';

import { useCurrentSite } from '../providers/currentSite/useCurrentSite';

export function useFeatureFlag() {
    const { currentSite } = useCurrentSite();

    return {
        hasFeature: (name: FeatureFlagName) => {
            return hasFeature(name, currentSite.locale);
        },
    };
}
