import cx from 'classnames';
import React, { HTMLAttributes, RefObject } from 'react';
import { useIntl } from 'react-intl';

import { useLocale } from '../../../../../core/i18n/Locale';
import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import { useFeatureFlag } from '../../../../../shared/hooks/useFeatureFlag';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { Container } from '../../../Container/Container';
import {
    SUMMER_SLUG_KEYWORD_BY_MARKET,
    ThematicItemSummerCampaign,
} from '../../Shared/WhereToGo/thematicItem/SummerCampaignItem/SummerCampaignItem';
import {
    ThematicItemWinterCampaign,
    WINTER_SLUG_KEYWORD_BY_MARKET,
} from '../../Shared/WhereToGo/thematicItem/WinterCampaignItem/WinterCampaignItem';
import { WhereToGoDropdownThematic } from '../../types';
import { DropdownContent } from '../DropdownContent/DropdownContents';

import style from './WhereToGoDropdownContent.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
    onClickOutside: () => void;
    travelTypes: WhereToGoDropdownThematic[];
    triggerRef: RefObject<HTMLElement | null>;
    what: WhereToGoDropdownThematic[];
    when: WhereToGoDropdownThematic[];
    who: WhereToGoDropdownThematic[];
};

export function WhereToGoDropdownContent({ travelTypes, what, when, who, ...props }: Props) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <DropdownContent {...props}>
            <Container>
                <div className={style.thematicsContainer}>
                    <ThematicSection
                        title={formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWho',
                        })}
                        onThematicClick={(thematic) => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.headerWhoThematicClicked({
                                    ...defaultProperties,
                                    block: 'header',
                                    thematic_slug: thematic.slug,
                                });
                            });
                        }}
                        thematics={who}
                    />
                    <ThematicSection
                        title={formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWhat',
                        })}
                        onThematicClick={(thematic) => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.headerWhatThematicClicked({
                                    ...defaultProperties,
                                    block: 'header',
                                    thematic_slug: thematic.slug,
                                });
                            });
                        }}
                        thematics={what}
                    />
                    <ThematicSection
                        title={formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicWhen',
                        })}
                        onThematicClick={(thematic) => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.headerWhenThematicClicked({
                                    ...defaultProperties,
                                    block: 'header',
                                    thematic_slug: thematic.slug,
                                });
                            });
                        }}
                        thematics={when}
                    />
                    <ThematicSection
                        title={formatMessage({
                            id: 'global.header.globalNav.whereToGo.thematicType',
                        })}
                        onThematicClick={(thematic) => {
                            trackEvent((ampli, defaultProperties) => {
                                ampli.headerHowThematicClicked({
                                    ...defaultProperties,
                                    block: 'header',
                                    thematic_slug: thematic.slug,
                                });
                            });
                        }}
                        thematics={travelTypes}
                    />
                </div>
                <div className={style.footer}>
                    <Button
                        priority="secondary"
                        reversed
                        className={style.cta}
                        href={generatePath('where_to_go')}
                    >
                        {formatMessage({
                            id: 'global.header.globalNav.whereToGo.cta',
                        })}
                    </Button>
                </div>
            </Container>
        </DropdownContent>
    );
}

type ThematicSectionProps = {
    thematics: WhereToGoDropdownThematic[];
    title: string;
    onThematicClick: (thematic: WhereToGoDropdownThematic) => void;
};

function ThematicSection({ thematics, title, onThematicClick }: ThematicSectionProps) {
    const { locale } = useLocale();
    const { hasFeature } = useFeatureFlag();

    if (thematics.length < 4) {
        return null;
    }

    return (
        <div className={style.thematicSection}>
            <div className={style.thematicListContainer}>
                <strong className={style.thematicTitle}>{title}</strong>
                <ul className={cx('font-xs-regular', style.thematicList)}>
                    {thematics.map((thematic) => {
                        const isWinterHighlight =
                            hasFeature('marketing_campaign_winter') &&
                            thematic.slug === WINTER_SLUG_KEYWORD_BY_MARKET[locale];

                        const isSummerHighlight =
                            hasFeature('marketing_campaign_summer') &&
                            thematic.slug === SUMMER_SLUG_KEYWORD_BY_MARKET[locale];

                        if (isWinterHighlight) {
                            return (
                                <ThematicItemWinterCampaign
                                    key={thematic.slug}
                                    thematic={thematic}
                                    onThematicClick={onThematicClick}
                                />
                            );
                        }

                        if (isSummerHighlight) {
                            return (
                                <ThematicItemSummerCampaign
                                    key={thematic.slug}
                                    thematic={thematic}
                                    onThematicClick={onThematicClick}
                                />
                            );
                        }

                        return (
                            <ThematicItem
                                key={thematic.slug}
                                thematic={thematic}
                                onThematicClick={onThematicClick}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

type ThematicItemProps = {
    thematic: WhereToGoDropdownThematic;
    onThematicClick: (thematic: WhereToGoDropdownThematic) => void;
};

function ThematicItem({ thematic, onThematicClick }: ThematicItemProps) {
    return (
        <li className={style.thematicListItem}>
            <MenuItem
                href={thematic.url}
                onClick={() => {
                    onThematicClick(thematic);
                }}
            >
                {thematic.label}
            </MenuItem>
        </li>
    );
}
