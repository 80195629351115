'use client';

import { useCallback, useEffect, useRef } from 'react';

import { logger } from '../../core/Log/logger';
import { useTrackEventAmplitude } from '../../core/Tracking/Amplitude/Amplitude';
import { useModal } from '../../design-system/components/modal/useModal';
import { useFeatureFlag } from '../../shared/hooks/useFeatureFlag';

import { DynamicExitModalNewsletter } from './ExitModalNewsletter/ExitModalNewsletter.dynamic';
import { DynamicMarketingOpNewsletterModal } from './MarketingOpNewsletterModal/MarketingOpNewsletterModal.dynamic';
import { DynamicThreePageViewsModalNewsletter } from './ThreePageViewsModalNewsletter/ThreePageViewsModalNewsletter.dynamic';

type ModalOrigin = 'exit' | 'three_page_views';

export function Marketing() {
    const { trackEvent } = useTrackEventAmplitude();
    const { hasFeature } = useFeatureFlag();

    const exitModalNewsletterProps = useModal();
    const threePageViewsModalNewsletterProps = useModal();
    const marketingOpModal = useModal();

    const modalOrigin = useRef<ModalOrigin | 'unhandled'>('unhandled');

    useEffect(() => {
        const handleShow = () => {
            if (hasFeature('marketing_op_newsletter_modal')) {
                marketingOpModal.open();
            } else {
                exitModalNewsletterProps.open();
            }
            modalOrigin.current = 'exit';

            trackEvent((ampli, defaultProperties) => {
                ampli.popinNewsletterDisplayed({
                    ...defaultProperties,
                    origin: 'exit',
                });
            });
        };

        document.addEventListener('show-exit-modal-newsletter', handleShow);

        return () => {
            document.removeEventListener('show-exit-modal-newsletter', handleShow);
        };
    }, [exitModalNewsletterProps, marketingOpModal, trackEvent, hasFeature]);

    useEffect(() => {
        const handleShow = () => {
            if (hasFeature('marketing_op_newsletter_modal')) {
                marketingOpModal.open();
            } else {
                threePageViewsModalNewsletterProps.open();
            }
            modalOrigin.current = 'three_page_views';

            trackEvent((ampli, defaultProperties) => {
                ampli.popinNewsletterDisplayed({
                    ...defaultProperties,
                    origin: 'three_page_views',
                });
            });
        };

        document.addEventListener('show-three-page-views-modal-newsletter', handleShow);

        return () => {
            document.removeEventListener('show-three-page-views-modal-newsletter', handleShow);
        };
    }, [threePageViewsModalNewsletterProps, marketingOpModal, trackEvent, hasFeature]);

    const trackCloseModalEvent = useCallback(() => {
        const origin = modalOrigin.current;

        if (origin === 'unhandled') {
            return logger.error('Unhandled modal origin', {});
        }

        trackEvent((ampli, defaultProperties) => {
            ampli.popinNewsletterClosed({
                ...defaultProperties,
                origin: origin,
            });
        });
    }, [trackEvent]);

    const handleCloseOpMarketingModalNewsletter = useCallback(() => {
        trackCloseModalEvent();
        marketingOpModal.close();
    }, [marketingOpModal, trackCloseModalEvent]);

    const handleCloseExitModalNewsletter = useCallback(() => {
        trackCloseModalEvent();
        exitModalNewsletterProps.close();
    }, [exitModalNewsletterProps, trackCloseModalEvent]);

    const handleCloseThreePageViewsModalNewsletter = useCallback(() => {
        trackCloseModalEvent();
        threePageViewsModalNewsletterProps.close();
    }, [threePageViewsModalNewsletterProps, trackCloseModalEvent]);

    return (
        <>
            {marketingOpModal.isOpen ? (
                <DynamicMarketingOpNewsletterModal
                    {...marketingOpModal}
                    close={handleCloseOpMarketingModalNewsletter}
                />
            ) : null}

            {exitModalNewsletterProps.isOpen ? (
                <DynamicExitModalNewsletter
                    {...exitModalNewsletterProps}
                    close={handleCloseExitModalNewsletter}
                />
            ) : null}

            {threePageViewsModalNewsletterProps.isOpen ? (
                <DynamicThreePageViewsModalNewsletter
                    {...threePageViewsModalNewsletterProps}
                    close={handleCloseThreePageViewsModalNewsletter}
                />
            ) : null}
        </>
    );
}
