import dynamic from 'next/dynamic';

import { Props } from './ThreePageViewsModalNewsletter';

export const DynamicThreePageViewsModalNewsletter = dynamic<Props>(
    () =>
        import('./ThreePageViewsModalNewsletter').then(({ ThreePageViewsModalNewsletter }) => {
            return ThreePageViewsModalNewsletter;
        }),
    { ssr: false },
);
