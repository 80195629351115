import dynamic from 'next/dynamic';

import { Props } from './MarketingOpNewsletterModal';

export const DynamicMarketingOpNewsletterModal = dynamic<Props>(
    () =>
        import('./MarketingOpNewsletterModal').then(({ MarketingOpNewsletterModal }) => {
            return MarketingOpNewsletterModal;
        }),
    { ssr: false },
);
