import dynamic from 'next/dynamic';

export const DynamicSiteSuggestionModal = dynamic(
    async () => {
        return import('./SiteSuggestionModal').then(({ SiteSuggestionModal }) => {
            return SiteSuggestionModal;
        });
    },
    {
        ssr: false,
    },
);
