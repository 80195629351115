import featureFlagsConfig from '../../configurations/featureFlags.json';
import { Locale } from '../i18n/Locale';

export function featureFlags() {
    return featureFlagsConfig as FeatureFlagsConfig;
}

export function hasFeature(name: FeatureFlagName, locale: Locale) {
    const flags = featureFlags();

    return !!flags[name].find((value: Locale) => {
        return locale === value;
    });
}

type FeatureFlagsConfig = {
    [key in FeatureFlagName]: Locale[];
};

export type FeatureFlagName = keyof typeof featureFlagsConfig;
