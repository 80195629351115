'use client';

import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface MapContextValue {
    token?: string;
}

export const MapContext = createContext<MapContextValue>({
    token: undefined,
});

export const MapProvider = ({ children, token }: PropsWithChildren<{ token?: string }>) => {
    const value = useMemo(() => {
        return {
            token,
        };
    }, [token]);

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMap = () => {
    return useContext(MapContext);
};
