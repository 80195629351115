import { useIntl } from 'react-intl';

import { useLocale } from '../../core/i18n/Locale';

import { useUrlGenerator } from './useUrlGenerator';

export type CommitmentLink = {
    name: string;
    label: string;
    url: string;
};

export function useCommitmentsLinks(): {
    [key: CommitmentLink['name']]: CommitmentLink;
} {
    const { formatMessage } = useIntl();
    const { generateBrandUrl } = useUrlGenerator();
    const { locale } = useLocale();

    const concept = {
        name: 'concept',
        label: formatMessage({ id: 'global.header.globalNav.concept.label' }),
        url: generateBrandUrl('concept'),
    };

    const impact = {
        name: 'impact',
        label: formatMessage({ id: 'global.header.globalNav.impact.label' }),
        url: generateBrandUrl('impact'),
    };

    const carbonNeutral = {
        name: 'carbon-neutral',
        label: formatMessage({ id: 'global.header.globalNav.carbonNeutral.label' }),
        url: generateBrandUrl('carbon_neutral'),
    };

    const bCorp = {
        name: 'b-corp',
        label: formatMessage({ id: 'global.header.globalNav.bCorp.label' }),
        url: generateBrandUrl('b_corp'),
    };

    const impactReport = {
        name: 'impact-report',
        label: formatMessage({ id: 'global.header.globalNav.impactReport.label' }),
        url:
            locale === 'fr-FR'
                ? 'https://issuu.com/evaneos/docs/evaneos-impact-report-fr-2024-5?fr=xKAE9_zU1NQ'
                : 'https://issuu.com/evaneos/docs/evaneos-impact-report-12_en_us_2023.09_20_1_?fr=xKAE9_zU1NQ ',
    };

    return {
        concept,
        impact,
        carbonNeutral,
        bCorp,
        impactReport,
    };
}
