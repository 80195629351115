import cx from 'classnames';
import {
    AnchorHTMLAttributes,
    forwardRef,
    FunctionComponent,
    PropsWithChildren,
    SVGAttributes,
} from 'react';

import style from './MenuItem.module.css';

type Props = PropsWithChildren<
    AnchorHTMLAttributes<HTMLAnchorElement> & {
        Icon?: FunctionComponent<SVGAttributes<SVGElement>>;
        inline?: boolean;
        size?: 'small' | 'medium';
    }
>;

export const MenuItem = forwardRef<HTMLAnchorElement, Props>(
    ({ children, Icon, inline, size = 'medium', ...anchorProps }, forwardedRef) => {
        return (
            <a
                {...anchorProps}
                className={cx(anchorProps.className, style.link, 'font-xs-regular', {
                    [style.inline]: inline,
                    [style.small]: size === 'small',
                    [style.medium]: size === 'medium',
                })}
                ref={forwardedRef}
            >
                {Icon ? <Icon className={style.icon} /> : null}
                <div className={style.text}>{children}</div>
            </a>
        );
    },
);
