import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
    className?: string;
    borderOnly?: boolean;
}

export const IconHeartFilled = ({ ...props }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconHeartFilled"
            {...props}
        >
            <path d="M13.3502 21.13C12.5902 21.82 11.4202 21.82 10.6602 21.12L10.5502 21.02C5.30015 16.27 1.87015 13.16 2.00015 9.28004C2.06015 7.58004 2.93015 5.95004 4.34015 4.99004C6.98015 3.19004 10.2402 4.03004 12.0002 6.09004C13.7602 4.03004 17.0202 3.18004 19.6602 4.99004C21.0702 5.95004 21.9402 7.58004 22.0002 9.28004C22.1402 13.16 18.7002 16.27 13.4502 21.04L13.3502 21.13Z" />
        </svg>
    );
};
