import * as Tabs from '@radix-ui/react-tabs';
import { TabsContentProps, TabsListProps, TabsTriggerProps } from '@radix-ui/react-tabs';
import cx from 'classnames';
import { forwardRef, SVGAttributes, ReactElement } from 'react';

import styles from './TabList.module.css';

export const TabRoot = Tabs.Root;
TabRoot.displayName = 'TabRoot';

export const TabList = forwardRef<
    HTMLDivElement,
    TabsListProps & { reversed?: boolean; direction?: 'horizontal' | 'vertical' }
>(({ children, reversed = false, direction = 'horizontal', ...props }, ref) => {
    return (
        <Tabs.List
            data-testid="TabList"
            {...props}
            className={cx(props.className, styles.tabList, {
                [styles.tabListReversed]: reversed,
                [styles.tabListVertical]: direction === 'vertical',
            })}
            ref={ref}
        >
            {children}
        </Tabs.List>
    );
});
TabList.displayName = 'TabList';

export const TabContent = forwardRef<HTMLDivElement, TabsContentProps>(
    ({ children, ...props }, ref) => {
        return (
            <Tabs.Content {...props} ref={ref}>
                {children}
            </Tabs.Content>
        );
    },
);
TabContent.displayName = 'TabContent';

export const Tab = forwardRef<HTMLButtonElement, TabsTriggerProps>(
    ({ children, ...props }, ref) => {
        return (
            <Tabs.Trigger
                data-testid="Tab"
                type="button"
                {...props}
                className={cx(props.className, 'font-xs-bold', styles.tab)}
                ref={ref}
            >
                {children}
            </Tabs.Trigger>
        );
    },
);
Tab.displayName = 'Tab';

export const ThematicTab = forwardRef<
    HTMLButtonElement,
    TabsTriggerProps & { Icon: (props: SVGAttributes<SVGElement>) => ReactElement }
>(({ Icon, children, ...props }, ref) => {
    return (
        <Tabs.Trigger
            data-testid="Tab"
            type="button"
            {...props}
            className={cx(props.className, 'font-xxs-bold', styles.thematicTab)}
            ref={ref}
        >
            <div className={styles.tabIconWrapper}>
                <Icon className={styles.tabIcon} />
            </div>
            {children}
        </Tabs.Trigger>
    );
});
Tab.displayName = 'ThematicTab';

export const NavMenuVerticalTab = forwardRef<
    HTMLButtonElement,
    TabsTriggerProps & { Icon: (props: SVGAttributes<SVGElement>) => ReactElement }
>(({ Icon, children, ...props }, ref) => {
    return (
        <Tabs.Trigger
            data-testid="Tab"
            type="button"
            {...props}
            className={cx(props.className, 'font-body-bold', styles.navMenuVerticalTab)}
            ref={ref}
        >
            <Icon className={styles.navMenuVerticalTabIcon} />
            {children}
        </Tabs.Trigger>
    );
});
Tab.displayName = 'NavMenuVerticalTab';
