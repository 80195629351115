import cx from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { logger } from '../../../../../core/Log/logger';
import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { PrimaryNavDestinationCard } from '../../../../../design-system/components/card/primary-nav-destination/PrimaryNavDestinationCard';
import { Button } from '../../../../../design-system/components/form/button/Button';
import { IconArrowRight } from '../../../../../design-system/components/icons/ArrowRight';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import {
    Tab,
    TabContent,
    TabList,
    TabRoot,
} from '../../../../../design-system/components/tabs/TabList';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useUser } from '../../../../../shared/providers/user/useUser';
import { imageLoaderForId } from '../../../Image/URL/ImageURL';

import style from './DestinationsContent.module.css';
import { useFetchContinent, useFetchWorld } from './DestinationsContentHooks';

export interface DestinationsDropdownContinent {
    id: number;
    name: string;
    slug: string;
    preposition: string;
    recommendationSlug:
        | 'all'
        | 'africa'
        | 'asia'
        | 'oceania'
        | 'south-america'
        | 'europa'
        | 'central-america'
        | 'north-america'
        | 'middle-east';
    topDestinations: DestinationsDropdownDestination[];
    otherDestinations: DestinationsDropdownDestination[];
}

export interface DestinationsDropdownDestination {
    id: number;
    coverPictureId: number;
    name: string;
    slug: string;
    preposition: string;
}

export interface DestinationsDropdownWorld {
    id: number;
    name: string;
    topDestinations: DestinationsDropdownDestination[];
    otherDestinations: DestinationsDropdownDestination[];
}

export type DestinationsDropdownContextContinent = DestinationsDropdownContinent | null;

interface DestinationDropdownProps {
    continents: DestinationsDropdownContinent[];
    contextContinent: DestinationsDropdownContextContinent;
    world: DestinationsDropdownWorld;
}

export function DestinationsContent(props: DestinationDropdownProps) {
    const { fetchWorld } = useFetchWorld();
    const { fetchContinent } = useFetchContinent();
    const { continents: propsContinents, contextContinent, world: propsWorld } = props;
    const [world, setWorld] = useState(propsWorld);
    const [continents, setContinents] = useState(() => {
        const orderedContinents = [];

        if (contextContinent) {
            orderedContinents.push(contextContinent);
        }

        propsContinents.forEach((continent) => {
            if (continent.id !== contextContinent?.id) {
                orderedContinents.push(continent);
            }
        });

        return orderedContinents;
    });

    const defaultSelectedTab = tabId(contextContinent ?? world);
    const fetchIds = useRef(
        contextContinent === null
            ? [world.id].concat(continents.map((continent) => continent.id))
            : [contextContinent.id],
    );
    const [fetchDoneIds, setFetchDoneIds] = useState([...fetchIds.current]);

    const fetchContinentOrWorld = useCallback(
        (continentOrWorld: DestinationsDropdownContinent | DestinationsDropdownWorld) => {
            if (fetchIds.current.includes(continentOrWorld.id)) {
                return Promise.resolve();
            }

            fetchIds.current = fetchIds.current.concat(continentOrWorld.id);

            if (continentOrWorld.id === world.id) {
                return fetchWorld().then(({ topDestinations, otherDestinations }) => {
                    setWorld({
                        ...world,
                        topDestinations,
                        otherDestinations,
                    });
                    setFetchDoneIds((ids) => ids.concat(world.id));
                });
            }

            return fetchContinent(continentOrWorld as DestinationsDropdownContinent).then(
                (continent) => {
                    setContinents((previousContinents) => {
                        return previousContinents.map((previousContinent) => {
                            return previousContinent.id === continent.id
                                ? continent
                                : previousContinent;
                        });
                    });
                    setFetchDoneIds((ids) => ids.concat(continent.id));
                },
            );
        },
        [fetchContinent, fetchWorld, world],
    );

    return (
        <TabRoot
            defaultValue={defaultSelectedTab}
            orientation="horizontal"
            onValueChange={(selectedTab) => {
                const continentOrWorld =
                    selectedTab === tabId(world)
                        ? world
                        : (continents.find((continent) => {
                              return tabId(continent) === selectedTab;
                          }) as DestinationsDropdownContinent);

                fetchContinentOrWorld(continentOrWorld).catch((error) => {
                    logger.error(
                        'User could not select continent in PrimaryNav Destinations Dropdown',
                        { selectedTab, continent: continentOrWorld },
                        error,
                    );
                });
            }}
        >
            <div className={style.tabListWrapper}>
                <TabList reversed id="DestinationsDropdown-TabList" aria-label="Continents Tabs">
                    <Tab
                        value={tabId(world)}
                        onMouseEnter={() => {
                            fetchContinentOrWorld(world).catch((error) => {
                                logger.error(
                                    'Pre fetch World on hover failed in PrimaryNav Destinations Dropdown',
                                    { world },
                                    error,
                                );
                            });
                        }}
                        className={style.tab}
                    >
                        {world.name}
                    </Tab>

                    {continents.map((continent) => (
                        <Tab
                            key={continent.id}
                            value={tabId(continent)}
                            onMouseEnter={() => {
                                fetchContinentOrWorld(continent).catch((error) => {
                                    logger.error(
                                        'Pre fetch Continent on hover failed in PrimaryNav Destinations Dropdown',
                                        { continent },
                                        error,
                                    );
                                });
                            }}
                            className={style.tab}
                        >
                            {continent.name}
                        </Tab>
                    ))}
                </TabList>
            </div>

            {fetchDoneIds.includes(world.id) ? (
                <TabPanel
                    key={world.id}
                    otherDestinations={world.otherDestinations}
                    topDestinations={world.topDestinations}
                    world={world}
                />
            ) : null}

            {continents.map((continent) => {
                if (!fetchDoneIds.includes(continent.id)) {
                    return null;
                }
                return (
                    <TabPanel
                        key={continent.id}
                        continent={continent}
                        otherDestinations={continent.otherDestinations}
                        topDestinations={continent.topDestinations}
                        world={world}
                    />
                );
            })}
        </TabRoot>
    );
}

function TabPanel(props: {
    continent?: DestinationsDropdownContinent;
    otherDestinations: DestinationsDropdownDestination[];
    topDestinations: DestinationsDropdownDestination[];
    world: DestinationsDropdownWorld;
}) {
    const { continent, otherDestinations, topDestinations, world } = props;
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    const anchorHref = continent
        ? generatePath('continent', { slug: continent.slug })
        : generatePath('destination_list');
    const anchorText = continent
        ? formatMessage(
              { id: 'global.header.globalNav.destinations.continentPage' },
              {
                  continentPreposition: continent.preposition,
                  continentName: continent.name,
              },
          )
        : formatMessage({
              id: 'global.header.globalNav.destinations.worldPage',
          });

    return (
        <TabContent
            value={tabId(continent ?? world)}
            className={style.tabContent}
            data-testid="DestinationsDropdown-TabPanel"
        >
            <TopDestinations continent={continent} destinations={topDestinations} />
            {continent && otherDestinations.length > 0 ? (
                <DestinationsDropdownOtherDestinations
                    continent={continent}
                    destinations={otherDestinations}
                />
            ) : null}
            <div className={style.section}>
                <Button
                    priority="secondary"
                    reversed={true}
                    data-testid="DestinationsDropdown-TabPanel-anchor"
                    href={anchorHref}
                    Icon={IconArrowRight}
                    iconPosition="right"
                    onClick={() => {
                        trackEvent((ampli, defaultProperties) => {
                            ampli.headerContinentClicked({
                                ...defaultProperties,
                                continent: continent ? continent.recommendationSlug : 'all',
                            });
                        });
                    }}
                >
                    {anchorText}
                </Button>
            </div>
        </TabContent>
    );
}

type TopDestinationsProps = {
    continent?: DestinationsDropdownContinent;
    destinations: DestinationsDropdownDestination[];
};

function TopDestinations(props: TopDestinationsProps) {
    const { generatePath } = useUrlGenerator();
    const { continent, destinations } = props;
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();
    const { user } = useUser();
    const hasAccessToRecommendation = user?.recommendationsIsEnabled;

    const title = continent
        ? formatMessage(
              {
                  id: 'global.header.globalNav.destinations.topDestinations.continent',
              },
              {
                  continentPreposition: continent.preposition,
                  continentName: continent.name,
              },
          )
        : formatMessage({
              id: 'global.header.globalNav.destinations.topDestinations.world',
          });

    return (
        <div className={style.section}>
            <div className={cx('font-m-extrabold', style.sectionTitle)}>{title}</div>
            <ul className={style.topDestinationsList}>
                {destinations.map((destination, index) => {
                    return (
                        <li key={destination.slug} className={style.topDestinationsListItem}>
                            <PrimaryNavDestinationCard
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.headerTopDestinationClicked({
                                            ...defaultProperties,
                                            destination_slug: destination.slug,
                                            block: 'header',
                                            card_number: index + 1,
                                            continent: continent
                                                ? continent.recommendationSlug
                                                : 'all',
                                        });
                                    });
                                }}
                                title={destination.name}
                                image={{
                                    alt: formatMessage(
                                        {
                                            id: 'global.header.globalNav.destinations.destinationAltTitle',
                                        },
                                        {
                                            destinationPreposition: destination.preposition,
                                            destinationName: destination.name,
                                        },
                                    ),
                                    src: destination.coverPictureId.toString(),
                                    sizes: '(min-width: 1200px) 115px, (min-width: 768px) 130px, (min-width: 576px) 170px, 110px',
                                    loader: imageLoaderForId({
                                        aspectRatio: PrimaryNavDestinationCard.PICTURE_RATIO,
                                    }),
                                }}
                                href={generatePath('destination', {
                                    destinationSlug: destination.slug,
                                })}
                                small={true}
                            />
                        </li>
                    );
                })}
                <li className={style.topDestinationsListItem}>
                    <a
                        className={style.findDestination}
                        onClick={() => {
                            trackEvent((ampli, defaultProperties) => {
                                if (hasAccessToRecommendation) {
                                    ampli.headerRecommendationClicked({
                                        ...defaultProperties,
                                        block: 'header',
                                    });
                                    return;
                                }

                                ampli.headerDestinationWheretogoClicked({
                                    ...defaultProperties,
                                    block: 'header',
                                    continent: continent ? continent.recommendationSlug : 'all',
                                });
                            });
                        }}
                        href={
                            hasAccessToRecommendation
                                ? generatePath('account_recommendations', {
                                      source: 'top-destinations',
                                  })
                                : generatePath('where_to_go')
                        }
                    >
                        <span className={cx(style.findDestinationText, 'font-xs-bold')}>
                            {formatMessage({
                                id: 'global.header.globalNav.destinations.findDestination',
                            })}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

type OtherDestinationsProps = {
    continent: DestinationsDropdownContinent;
    destinations: DestinationsDropdownDestination[];
};

function DestinationsDropdownOtherDestinations(props: OtherDestinationsProps) {
    const { continent, destinations } = props;
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <div className={style.section}>
            <div className={cx('font-m-extrabold', style.sectionTitle)}>
                {formatMessage(
                    {
                        id: 'global.header.globalNav.destinations.otherDestinations',
                    },
                    {
                        continentPreposition: continent.preposition,
                        continentName: continent.name,
                    },
                )}
            </div>
            <ul className={style.otherDestinationsList}>
                {destinations.map((destination, index) => {
                    return (
                        <li key={destination.slug}>
                            <MenuItem
                                href={generatePath('destination', {
                                    destinationSlug: destination.slug,
                                })}
                                inline /* inline-flex for Safari compatibility with column-count */
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.headerOtherDestinationClicked({
                                            ...defaultProperties,
                                            destination_slug: destination.slug,
                                            block: 'header',
                                            card_number: index + 1,
                                            continent: continent
                                                ? continent.recommendationSlug
                                                : 'all',
                                        });
                                    });
                                }}
                                title={formatMessage(
                                    {
                                        id: 'global.header.globalNav.destinations.destinationAltTitle',
                                    },
                                    {
                                        destinationPreposition: destination.preposition,
                                        destinationName: destination.name,
                                    },
                                )}
                            >
                                {destination.name}
                            </MenuItem>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function tabId(continentOrWorld: DestinationsDropdownContinent | DestinationsDropdownWorld) {
    return `DestinationsDropdown-Tab-${continentOrWorld.id}`;
}
