import cx from 'classnames';

import { IconArrowLeft } from '../../../../../../design-system/components/icons/ArrowLeft';
import { IconCross } from '../../../../../../design-system/components/icons/Cross';

import { NavItemButton } from '../../../../../../design-system/components/nav/nav-item/NavItem';

import style from './MobileSubNav.module.css';

type Props = {
    title?: string;
    onClose: () => void;
    onPrevious: () => void;
    className?: string;
    sticky?: boolean;
    showPreviousButton: boolean;
};

export function MobileSubNav({
    title,
    onClose,
    onPrevious,
    className,
    sticky,
    showPreviousButton,
}: Props) {
    return (
        <div
            className={cx(style.mobileSubNav, className, {
                [style.sticky]: sticky,
            })}
        >
            {showPreviousButton ? (
                <NavItemButton iconOnly iconLeading={<IconArrowLeft />} onClick={onPrevious} />
            ) : null}
            <div className={style.titleWrapper}>
                {title ? <div className={cx('font-body-bold', style.title)}>{title}</div> : null}
            </div>
            <NavItemButton iconOnly iconLeading={<IconCross />} onClick={() => onClose()} />
        </div>
    );
}
