import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import {
    CommitmentLink,
    useCommitmentsLinks,
} from '../../../../../shared/hooks/useCommitmentsLinks';

export function usePrimaryNavCommitmentsLinks(): Array<
    CommitmentLink & {
        trackClick: () => void;
    }
> {
    const { trackEvent } = useTrackEventAmplitude();
    const { concept, impact, carbonNeutral, bCorp, impactReport } = useCommitmentsLinks();

    return [
        {
            ...concept,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerConceptClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            },
        },
        {
            ...impact,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerOurImpactClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            },
        },
        {
            ...carbonNeutral,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerCarbonNeutralClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            },
        },
        {
            ...bCorp,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerBCorpClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            },
        },
        {
            ...impactReport,
            trackClick: () => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerImpactReportClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            },
        },
    ];
}
