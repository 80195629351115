import Cookies from 'js-cookie';

import { Locale } from '../../core/i18n/Locale';
import {
    findSiteByLang,
    findSiteByLocale,
    getAllSitesEnabled,
    getSiteByLocale,
    Site,
} from '../../core/Site/Site';
import { useUser } from '../../shared/providers/user/useUser';
import { UserAgent } from '../../shared/utilities/browser/UserAgent';
import { generateUrl } from '../../shared/utilities/routing/routing';

const COOKIE_NAME = 'ev-site-suggestion';

const COOKIE_VALUE = '1';

const COOKIE_EXPIRES_AFTER_DAYS = 2;

const FROM_SUGGESTION_PARAM = 'fromSuggestion';

export function shouldShowSiteSuggestionModal(): boolean {
    if (
        typeof window === 'undefined' ||
        hasBeenRedirectedFromSuggestion() ||
        isInIframe() ||
        UserAgent.current().isBot() ||
        '_DATADOG_SYNTHETICS_BROWSER' in window
    ) {
        return false;
    }

    return !siteSuggestionModalAlreadyShown();
}

function formatSuggestedSiteUrl(url: string) {
    const formattedUrl = new URL(url);

    formattedUrl.searchParams.append(FROM_SUGGESTION_PARAM, '1');

    return formattedUrl.toString();
}

function removeFromSuggestionParamFromUrl() {
    window.history.replaceState(null, document.title, window.location.pathname);
}

function hasBeenRedirectedFromSuggestion(): boolean {
    if (typeof window === 'undefined') {
        return false;
    }

    const url = new URL(window.location.href);

    return url.searchParams.has(FROM_SUGGESTION_PARAM);
}

function handleHasBeenRedirectedFromSuggestion() {
    setSiteSuggestionModalHasBeenShown();
    removeFromSuggestionParamFromUrl();
}

function siteSuggestionModalAlreadyShown() {
    return Cookies.get(COOKIE_NAME) === COOKIE_VALUE;
}

export function setSiteSuggestionModalHasBeenShown() {
    return Cookies.set(COOKIE_NAME, COOKIE_VALUE, { expires: COOKIE_EXPIRES_AFTER_DAYS });
}

function isInIframe(): boolean {
    return window.location !== window.parent.location;
}

function alternateLinks() {
    return Array.from(document.querySelectorAll('[hreflang]')).map((alternate) => {
        return {
            href: alternate.getAttribute('href') as string,
            hrefLang: alternate.getAttribute('hreflang') as string,
        };
    });
}

export function alternateLinkForLocale(locale: Locale): string {
    const site = getSiteByLocale(locale);
    const alternates = alternateLinks();
    const alternateForSelectedSite = alternates.find((alternate) => {
        return alternate.hrefLang === site.hrefLang;
    });

    return formatSuggestedSiteUrl(
        alternateForSelectedSite
            ? alternateForSelectedSite.href
            : generateUrl('home', site.locale, {}, true),
    );
}

export function getSiteMatchingUserLang(): Site | null {
    if (typeof window === 'undefined') {
        return null;
    }

    const enabledSites = getAllSitesEnabled();
    const { languages } = window.navigator;

    for (const userLangOrLocale of languages) {
        const siteFromLocale = findSiteByLocale(userLangOrLocale, enabledSites);
        if (siteFromLocale) {
            return siteFromLocale;
        }

        const siteFromLang = findSiteByLang(userLangOrLocale, enabledSites);
        if (siteFromLang) {
            return siteFromLang;
        }
    }

    return null;
}

export function useSiteSuggestionModal(): boolean {
    const { user, isUserFetched } = useUser();

    if (hasBeenRedirectedFromSuggestion()) {
        handleHasBeenRedirectedFromSuggestion();
        return false;
    }

    return shouldShowSiteSuggestionModal() && isUserFetched && !user;
}
