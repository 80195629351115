'use client';

import Cookies from 'js-cookie';

import { useEffect } from 'react';

import { addUserAndSilverpop } from '../../components/Newsletter/NewsletterService';
import { md5 } from '../../shared/utilities/crypto/md5';

export function GlobalSetup() {
    // @todo rename GlobalVariablesSetup
    useEffect(() => {
        window.md5 = md5;
        window.gtm = {
            cookies: Cookies,
            addUserNewsletter: addUserAndSilverpop,
        };
    }, []);

    return null;
}
