import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { DestinationsDropdownContinent, DestinationsDropdownDestination } from '../../types';

interface ContinentResponseJSON {
    continent: DestinationsDropdownContinent;
}

interface WorldResponseJSON {
    topDestinations: DestinationsDropdownDestination[];
    otherDestinations: DestinationsDropdownDestination[];
}

export function useFetchContinent() {
    const { generatePath } = useUrlGenerator();

    return {
        fetchContinent: (continent: DestinationsDropdownContinent) => {
            const url = generatePath('api_destination_dropdown_continent', {
                continent: continent.slug,
            });

            return fetch(url, {
                headers: {
                    accept: 'application/json',
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then(({ continent }: ContinentResponseJSON) => {
                    return continent;
                });
        },
    };
}

export function useFetchWorld() {
    const { generatePath } = useUrlGenerator();

    return {
        fetchWorld: () => {
            const url = generatePath('api_destination_dropdown_world');

            return fetch(url, {
                headers: {
                    accept: 'application/json',
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then(({ topDestinations, otherDestinations }: WorldResponseJSON) => {
                    return {
                        topDestinations,
                        otherDestinations,
                    };
                });
        },
    };
}
