'use client';

import NextScript from 'next/script';
import { useEffect } from 'react';

type ConsentID = number | string;
type ConsentStatus = boolean | undefined;

interface PreferencesObject {
    show(): void;
    hide(): void;
    isVisible(): boolean;
}

interface NoticeObject {
    show(): void;
    configure(): void;
    hide(): void;
    isVisible(): boolean;
}

interface PolicyObject {
    close(): void;
    open(): void;
}

interface UserConsentStatus {
    purposes: {
        enabled: unknown[];
        disabled: unknown[];
    };
    vendors: {
        enabled: unknown[];
        disabled: unknown[];
    };
}

interface UserStatus {
    consent_string: string;
    created: string;
    updated: string;
    user_id: string;
    purposes: {
        consent: {
            enabled: unknown[];
            disabled: unknown[];
        };
        legitimate_interest: {
            enabled: unknown[];
            disabled: unknown[];
        };
    };
    vendors: {
        consent: {
            enabled: unknown[];
            disabled: unknown[];
        };
        legitimate_interest: {
            enabled: unknown[];
            disabled: unknown[];
        };
    };
}

export interface DidomiObject {
    version: string;
    on(event: string, eventHandler: () => void): void;

    preferences: PreferencesObject;
    notice: NoticeObject;
    policy: PolicyObject;
    Purposes: {
        [key: string]: unknown;
    };

    getConfig(): unknown;
    getExperiment(): unknown;
    getLanguage(): unknown;
    getPurposes(): unknown;
    getRequiredPurposeIds(): unknown;
    getTranslationAsHTML(): unknown;
    getUserStatus(): UserStatus;
    getUserConsentStatusForAll(): UserConsentStatus;
    getUserConsentToken(): unknown;
    getVendors(): unknown;
    getRequiredVendorIds(): unknown;
    getUserConsentStatusForVendor(vendorId: ConsentID): ConsentStatus;
    getUserConsentStatusForPurpose(purposeId: ConsentID): ConsentStatus;
    getUserConsentStatus(purposeId: ConsentID, vendorId: ConsentID): boolean | undefined;
    getObservableOnUserConsentStatusForVendor(vendorId: ConsentID): unknown;
    getPurposeById(id: ConsentID): unknown;
    getVendorById(id: ConsentID): unknown;
    getRequiredPurposes(type?: string): unknown;
    getRequiredVendors(type?: string): unknown;

    setUserConsentStatusForAll(): void;
    setUserDisagreeToAll(): void;
    setUserAgreeToAll(): void;
    setConfigParameter(param: unknown, value: unknown): void;
    setUserConsentStatus(purposeId: ConsentID, vendorId: ConsentID, value: unknown): void;

    isConsentRequired(): boolean;
    isUserConsentStatusPartial(): boolean;
    shouldConsentBeCollected(): boolean;

    configure(): unknown;
    openTransaction(): unknown;
    reset(): unknown;

    [key: string]: unknown;
}

export function DidomiScript() {
    useEffect(() => {
        // Script used to clean all non useful cookies after consent changed as denied
        // ref: https://support.didomi.io/fr/comment-supprimer-les-cookies-du-navigateur
        if (typeof window !== 'undefined') {
            const itemsToKeep = [
                'euconsent-v2',
                'didomi_token',
                'PHPSESSID',
                'userpref-currency',
                'evaneos_auth_token',
                'Cfuid',
                'Overlay',
                'new_user_social_login',
                'exit_generique-news_abtasty_',
                '3PV_generique-news_abtasty3b',
                'esper_join_trip',
                'esper_seentravelerstab',
                'esper_seenflightpartner',
                'esper_feature_flags',
            ];

            const getCookieValue = (cookieName: string) => {
                const [cookie] = document.cookie.split(';').filter((cookieValue) => {
                    return cookieValue.indexOf(cookieName) !== -1;
                });

                if (cookie) {
                    return cookie.split('=')[1];
                }
            };

            const deleteCookie = function (name: string, domain: string, path?: string) {
                path = path || '/';

                const cookie = [
                    name + '=',
                    'expires=Thu, 01 Jan 1970 00:00:01 GMT',
                    'path=' + path,
                ];

                if (domain) {
                    cookie.push('domain=' + domain);
                }

                document.cookie = cookie.join(';');
            };

            const areAllVendorsAndPurposesDisabled = function () {
                if (!window.Didomi) {
                    return true;
                }

                const enabledEntities = [];
                const disabledEntities = [];
                const data = window.Didomi.getUserStatus();

                data.vendors.consent.enabled.forEach(function (entity) {
                    enabledEntities.push(entity);
                });

                data.purposes.consent.enabled.forEach(function (entity) {
                    enabledEntities.push(entity);
                });

                data.vendors.consent.disabled.forEach(function (entity) {
                    disabledEntities.push(entity);
                });

                data.purposes.consent.disabled.forEach(function (entity) {
                    disabledEntities.push(entity);
                });

                /**
                 * We check that we don't have unknown enabled entities
                 * and that disabled entities are present
                 */
                return enabledEntities.length === 0 && disabledEntities.length > 0;
            };

            let consentEventsCount = 0;
            const existingConsentString = getCookieValue('euconsent-v2');

            window.didomiEventListeners = window.didomiEventListeners || [];

            window.didomiEventListeners.push({
                event: 'consent.changed',
                listener: function () {
                    /**
                     * We catch consent update event in 2 cases:
                     * -> 1. When user gives consent and updates it without the page reload (via `consentEventsCount` value)
                     * -> 2. When user gives consent and updates it after the page reload (via `existingConsentString` value)
                     */
                    const consentUpdate = consentEventsCount > 0 ? true : !!existingConsentString;
                    if (consentUpdate && areAllVendorsAndPurposesDisabled()) {
                        /**
                         * Consent has been given previously and this is a consent update
                         */
                        const cookiesToDelete = document.cookie
                            .split(';')
                            .map(function (cookie) {
                                return cookie.split('=')[0].trim();
                            })
                            .filter(function (cookieName) {
                                return itemsToKeep.indexOf(cookieName) === -1;
                            });

                        /**
                         * Delete cookies
                         */
                        cookiesToDelete.forEach(function (cookieToDelete) {
                            /*
                                Delete from every possible domain (based on the current page domain) :
                            */
                            const domains = (
                                '.#' + document.location.host.replace('.', '#.#')
                            ).split('#');

                            while (domains.length) {
                                const possibleDomain = domains.join('');

                                deleteCookie(cookieToDelete, possibleDomain);

                                domains.shift();
                            }
                        });

                        const localStorageItemsToDelete = Object.keys(window.localStorage).filter(
                            function (localStorageItemName) {
                                return itemsToKeep.indexOf(localStorageItemName) === -1;
                            },
                        );

                        /**
                         * Delete local storage items
                         */
                        localStorageItemsToDelete.forEach(function (localStorageItemName) {
                            window.localStorage.removeItem(localStorageItemName);
                        });

                        // Reload the page
                        window.location.reload();
                    }

                    consentEventsCount++;
                },
            });
        }
    }, []);

    return (
        <NextScript
            id="didomiScript"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
                else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
                var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
                if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
                  var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("4a86310c-cf1a-4237-bb40-db435650d44e")})();
                `,
            }}
        />
    );
}
