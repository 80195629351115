import cx from 'classnames';
import Image, { ImageProps } from 'next/image';
import { HTMLAttributeAnchorTarget, MouseEvent } from 'react';

import { IntrinsicElement } from '../../../../shared/utilities/types/IntrinsicElement';
import { RATIO, Ratio } from '../../ratio/Ratio';

import { Typography } from '../../typography/Typography';

import style from './SquareCard.module.css';

interface SquareTileProps {
    href: string;
    image: ImageProps;
    target?: HTMLAttributeAnchorTarget;
    title: string;
    small?: boolean;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
    titleElement?: IntrinsicElement;
}

export function SquareCard(props: SquareTileProps) {
    const { href, image, target, title, small, onClick, titleElement = 'span' } = props;

    return (
        <div
            className={cx(style.card, 'imageOverlayHoverDark', {
                [style.small]: small,
            })}
        >
            <Ratio ratio={SquareCard.PICTURE_RATIO}>
                <Image {...image} alt={image.alt} fill className="imageCover" />
            </Ratio>
            <a
                onClick={onClick}
                className={style.link}
                href={href}
                target={target}
                title={image.alt}
            >
                <Typography
                    as={titleElement}
                    scale={small ? 'primary-xs-bold' : 'primary-m-extra-bold'}
                    className={style.title}
                >
                    {title}
                </Typography>
            </a>
        </div>
    );
}

SquareCard.PICTURE_RATIO = RATIO['1/1'];
