import cx from 'classnames';
import React, { HTMLAttributes, PropsWithChildren, RefObject, useEffect, useRef } from 'react';

import styles from './DropdownContents.module.css';

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
    onClickOutside: () => void;
    triggerRef: RefObject<HTMLElement | null>;
};

export function DropdownContent({ children, onClickOutside, triggerRef, ...props }: Props) {
    const ref = useRef(null);

    useOnClickOutside([ref, triggerRef], () => {
        if (!props.hidden) {
            onClickOutside();
        }
    });

    return (
        <div {...props} className={cx(props.className, styles.dropdownContent)} ref={ref}>
            {children}
        </div>
    );
}

function useOnClickOutside<T extends HTMLElement | null>(
    refs: Array<RefObject<T>>,
    clickOutsideHandler: () => void,
): void {
    useEffect(() => {
        function handleBodyClick(event: HTMLElementEventMap['click']) {
            let clickedAnyRef = false;

            refs.forEach((ref) => {
                const element = ref?.current;

                if (!element) {
                    return;
                }

                if (element.contains(event.target as Node)) {
                    clickedAnyRef = true;
                }
            });

            if (!clickedAnyRef) {
                clickOutsideHandler();
            }
        }

        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [refs, clickOutsideHandler]);
}
