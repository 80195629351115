import React from 'react';
import { useIntl } from 'react-intl';

import { Container } from '../../../../Container/Container';
import { DestinationsContent } from '../../../Shared/Destinations/DestinationsContent';
import { DestinationDropDown, PrimaryNavValue } from '../../../types';

import { MobileSubNav } from '../MobileSubNav/MobileSubNav';

import { MobileView } from '../MobileView';

type Props = {
    destinationsDropdown: DestinationDropDown;
    onNavValueChange: (value: PrimaryNavValue) => void;
};

export function DestinationsMobileView({ destinationsDropdown, onNavValueChange }: Props) {
    const { formatMessage } = useIntl();

    return (
        <MobileView>
            <MobileSubNav
                title={formatMessage({
                    id: 'global.header.globalNav.destinations.label',
                })}
                showPreviousButton={true}
                onClose={() => {
                    onNavValueChange(null);
                }}
                onPrevious={() => {
                    onNavValueChange('MOBILE_MENU');
                }}
            />
            <Container>
                <DestinationsContent
                    continents={destinationsDropdown.continents}
                    contextContinent={destinationsDropdown.contextContinent}
                    world={destinationsDropdown.world}
                />
            </Container>
        </MobileView>
    );
}
