'use client';

import * as Collapsible from '@radix-ui/react-collapsible';
import cx from 'classnames';
import { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { logger } from '../../core/Log/logger';
import { ampli } from '../../core/Tracking/Amplitude/__AUTO_GENERATED__';
import { TrackingProperties } from '../../core/Tracking/Amplitude/Amplitude';
import { Button } from '../../design-system/components/form/button/Button';
import { Checkbox } from '../../design-system/components/form/checkbox/Checkbox';
import { Input } from '../../design-system/components/form/input/Input';
import { Destination } from '../../resources/destination/domain/types/destination';
import { useUrlGenerator } from '../../shared/hooks/useUrlGenerator';
import { useUser } from '../../shared/providers/user/useUser';
import { isValidEmail } from '../../shared/utilities/email/isValidEmail';
import { PickDomain } from '../../shared/utilities/types/PickDomain';

import style from './Newsletter.module.css';
import { useNewsletter } from './NewsletterHook';

type Props = {
    subscribeMode: number;
    subscribeModeDetail?: string;
    acquisitionTags: string[];
    destination?: PickDomain<Destination, 'slug'>;
    className?: string;
    title?: string;
    subTitle?: string;
    buttonLabel?: string;
    theme?: 'green';
    afterSubmit?: () => void;
    trackingInformation: {
        subscription: TrackingProperties<typeof ampli.newsletterSubscribed>;
    };
};

interface FormElements extends HTMLFormControlsCollection {
    email?: HTMLInputElement;
    confirmation: HTMLInputElement;
}

interface NewsletterFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

export function Newsletter({
    subscribeMode,
    acquisitionTags,
    subscribeModeDetail,
    destination,
    className,
    title,
    subTitle,
    buttonLabel,
    theme,
    afterSubmit,
    trackingInformation,
}: Props) {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { user } = useUser();
    const { send } = useNewsletter();
    const [dataPolicyVisible, setDataPolicyVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = (e: FormEvent<NewsletterFormElement>) => {
        e.preventDefault();

        const emailInput = e.currentTarget.elements.email?.value || '';
        const confirmed = e.currentTarget.elements.confirmation.checked;
        const email = user?.email ?? emailInput;

        if (!user && !isValidEmail(emailInput)) {
            setError(
                formatMessage({
                    id: 'global.component.newsletter.error.emailNotValid',
                }),
            );
            return;
        }

        send({
            email,
            subscribeMode,
            acquisitionTags,
            subscribeModeDetail,
            destinationSlug: destination?.slug,
            confirmed,
            trackingInformation,
        })
            .then(() => {
                setSubmitted(true);
                if (afterSubmit) {
                    afterSubmit();
                }
            })
            .catch((error) => {
                setError(
                    formatMessage({
                        id: 'global.component.newsletter.error.retry',
                    }),
                );

                logger.error(
                    'Newsletter send failed',
                    {
                        subscribeMode,
                        acquisitionTags,
                        subscribeModeDetail,
                        destinationSlug: destination?.slug,
                        confirmed,
                    },
                    error,
                );
            });
    };

    return (
        <form
            onSubmit={onSubmit}
            className={cx(style.form, className, {
                [style.themeGreen]: theme === 'green',
            })}
        >
            {!submitted ? (
                <>
                    <div className={cx('font-l-extrabold', style.title)}>
                        {title ? title : formatMessage({ id: 'global.component.newsletter.title' })}
                    </div>
                    <div className={style.subTitle}>
                        {subTitle
                            ? subTitle
                            : formatMessage({ id: 'global.component.newsletter.subTitle' })}
                    </div>
                    <div className={style.checkboxWrapper}>
                        <Checkbox
                            checkboxProps={{
                                checked: false,
                                name: 'confirmation',
                                required: true,
                            }}
                            label={formatMessage({
                                id: 'global.component.newsletter.genericOptinLabel',
                            })}
                        />
                    </div>
                    <div className={style.inputButtonWrapper}>
                        {user === null ? (
                            <Input
                                className={style.input}
                                inputProps={{
                                    type: 'email',
                                    name: 'email',
                                    defaultValue: '',
                                    placeholder: formatMessage({
                                        id: 'global.component.newsletter.inputPlaceholder',
                                    }),
                                    required: true,
                                }}
                                label={formatMessage({
                                    id: 'global.component.newsletter.inputLabel',
                                })}
                                error={error}
                            />
                        ) : null}
                        <Button
                            className={style.button}
                            type="submit"
                            priority="secondary"
                            reversed={theme === 'green'}
                        >
                            {buttonLabel ||
                                formatMessage({
                                    id: 'global.component.newsletter.submitLabel',
                                })}
                        </Button>
                    </div>
                    <div
                        className={cx(style.cgu, 'font-xs-regular')}
                        dangerouslySetInnerHTML={{
                            __html: formatMessage(
                                {
                                    id: 'global.component.newsletter.cgu',
                                },
                                {
                                    linkStart: `<a
                                    href=${generatePath('cgu')}
                                    target="_blank"
                                    class="font-xs-bold link"
                                >`,
                                    linkEnd: '</a>',
                                },
                            ),
                        }}
                    />
                    <Collapsible.Root
                        open={dataPolicyVisible}
                        onOpenChange={setDataPolicyVisible}
                        className={style.dataPolicy}
                    >
                        <Collapsible.Trigger
                            className={cx(style.dataPolicyButton, 'font-xs-bold link')}
                        >
                            {formatMessage({
                                id: 'global.component.newsletter.cookiePolicyTitle',
                            })}
                        </Collapsible.Trigger>
                        <Collapsible.Content className={style.dataPolicyContent}>
                            {formatMessage({
                                id: 'global.component.newsletter.cookiePolicyContent',
                            })}
                        </Collapsible.Content>
                    </Collapsible.Root>
                </>
            ) : null}
            {submitted ? (
                <div>
                    <div className={cx(style.inscriptionDoneTitle, 'font-l-extrabold')}>
                        {formatMessage({
                            id: 'global.component.newsletter.confirmationTitle',
                        })}
                    </div>
                    <div>
                        {formatMessage({
                            id: 'global.component.newsletter.confirmationSubTitle',
                        })}
                    </div>
                </div>
            ) : null}
        </form>
    );
}
