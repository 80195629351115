import NextScript from 'next/script';

interface Props {
    gtmId: string;
    gtmData: {
        [key: string]: unknown;
    };
}

export function GTMScript({ gtmId, gtmData }: Props) {
    const initialData = JSON.stringify(gtmData);

    return (
        <>
            <NextScript
                id="gtmScript"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer=window.dataLayer||[];window.dataLayer.push(${initialData});
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
j.async=false;j.defer=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`,
                }}
            />
            <noscript>
                <iframe
                    src={`//www.googletagmanager.com/ns.html?id=${gtmId}`}
                    height="0"
                    width="0"
                    // eslint-disable-next-line react/forbid-dom-props
                    style={{
                        display: 'none',
                        visibility: 'hidden',
                    }}
                />
            </noscript>
        </>
    );
}
