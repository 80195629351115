import Image from 'next/image';

import React from 'react';

import { Locale } from '../../../../../../../core/i18n/Locale';
import { IconArrowRight } from '../../../../../../../design-system/components/icons/ArrowRight';
import { imageLoaderForPath } from '../../../../../Image/URL/ImageURL';

import { WhereToGoDropdownThematic } from '../../../../types';

import style from './WinterCampaignItem.module.css';

export const WINTER_SLUG_KEYWORD_BY_MARKET: Record<Locale, string> = {
    'de-CH': 'winter-reisen',
    'de-DE': 'winter-reisen',
    'en-CA': '',
    'en-GB': '',
    'en-US': '',
    'es-ES': 'viaje-invierno',
    'fr-FR': 'voyage-hiver',
    'it-IT': 'viaggio-inverno',
    'nl-NL': 'reizen-winter',
};
type ThematicItemWinterCampaignProps = {
    thematic: WhereToGoDropdownThematic;
    onThematicClick: (thematic: WhereToGoDropdownThematic) => void;
};

export function ThematicItemWinterCampaign({
    thematic,
    onThematicClick,
}: ThematicItemWinterCampaignProps) {
    return (
        <li className={style.thematicListItemWinterCampaign__wrapper}>
            <a
                href={thematic.url}
                onClick={() => {
                    onThematicClick(thematic);
                }}
                className={style.thematicListItemWinterCampaign__container}
            >
                <Image
                    className={style.thematicListItemWinterCampaignContainerImage}
                    alt="Winter campaign"
                    src="/campaign/winter/winter-background.png"
                    loader={imageLoaderForPath}
                    fill
                />
                <div className={style.thematicListItemWinterCampaign__label}>
                    <div className="font-xs-bold">{thematic.label}</div>
                    <IconArrowRight className={style.thematicListItemWinterCampaign__icon} />
                </div>
            </a>
        </li>
    );
}
