export class UserAgent {
    private readonly userAgent: string;

    constructor(userAgent: string) {
        this.userAgent = userAgent;
    }

    public isBot(): boolean {
        return (
            this.isGoogleBot() ||
            this.isGoogleLighthouseBot() ||
            this.isGoogleAPIsBot() ||
            this.isGoogleAdsBot() ||
            this.isGoogleAdSenseBot() ||
            this.isWebPageTestBot() ||
            this.isDuckDuckGoBot() ||
            this.isYahooBot() ||
            this.isBingBot()
        );
    }

    public isGoogleBot(): boolean {
        return /googlebot/i.test(this.userAgent);
    }

    public isGoogleLighthouseBot(): boolean {
        return /(google page speed insights)|(chrome-lighthouse)/i.test(this.userAgent);
    }

    public isGoogleAPIsBot(): boolean {
        return /apis-google/i.test(this.userAgent);
    }

    public isGoogleAdSenseBot(): boolean {
        return /mediapartners-google/i.test(this.userAgent);
    }

    public isGoogleAdsBot(): boolean {
        return /adsbot-google/i.test(this.userAgent);
    }

    public isWebPageTestBot(): boolean {
        return /ptst\//i.test(this.userAgent);
    }

    public isBingBot(): boolean {
        return /bingbot/i.test(this.userAgent);
    }

    public isDuckDuckGoBot(): boolean {
        return /duckduckbot/i.test(this.userAgent);
    }

    public isYahooBot(): boolean {
        return /yahoo!/i.test(this.userAgent);
    }

    public static current() {
        return new UserAgent(window.navigator.userAgent);
    }
}
