'use client';

import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useBreakPoint } from '../BreakPoint/BreakPoint';

import style from './PrimaryNav.module.css';
import { ResponsiveMenu } from './ResponsiveMenu';

import { DestinationDropDown, PrimaryNavValue, WhereToGoDropdown } from './types';

export type PrimaryNavData = {
    destinationsDropdown: DestinationDropDown;
    whereToGoDropdown: WhereToGoDropdown;
};

type Props = {
    destinationsDropdown?: PrimaryNavData['destinationsDropdown'];
    whereToGoDropdown?: PrimaryNavData['whereToGoDropdown'];
    noMenu?: true;
    previewMode?: boolean;
};

export function PrimaryNav({
    destinationsDropdown,
    whereToGoDropdown,
    noMenu,
    previewMode,
}: Props) {
    const { isXs, isSm, isMd, isLg } = useBreakPoint();
    const [value, setValue] = useState<PrimaryNavValue>(null);
    const isMobileBreakPoint = isXs() || isSm() || isMd() || isLg();

    useEffect(() => {
        if (value && isMobileBreakPoint) {
            document.body.classList.add('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [value, isMobileBreakPoint]);

    return (
        <header
            className={cx(style.primaryNav, {
                [style.isOpen]: value !== null && value !== 'USER',
                [style.previewMode]: previewMode,
            })}
        >
            <ResponsiveMenu
                destinationsDropdown={destinationsDropdown}
                whereToGoDropdown={whereToGoDropdown}
                noMenu={noMenu}
                value={value}
                setValue={setValue}
                previewMode={previewMode}
            />
        </header>
    );
}
