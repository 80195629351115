import dynamic from 'next/dynamic';

import { Props } from './ExitModalNewsletter';

export const DynamicExitModalNewsletter = dynamic<Props>(
    () =>
        import('./ExitModalNewsletter').then(({ ExitModalNewsletter }) => {
            return ExitModalNewsletter;
        }),
    { ssr: false },
);
