import cx from 'classnames';
import { Dispatch, forwardRef, MouseEvent, MouseEventHandler, SetStateAction, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../core/Tracking/Amplitude/Amplitude';
import { IconAccountCircle } from '../../../design-system/components/icons/AccountCircle';
import { IconBurger } from '../../../design-system/components/icons/Burger';
import { IconChevronDown } from '../../../design-system/components/icons/ChevronDown';
import { EvaneosLogo } from '../../../design-system/components/illustration/EvaneosLogo';
import { useModal } from '../../../design-system/components/modal/useModal';
import { NavItemButton, NavItemLink } from '../../../design-system/components/nav/nav-item/NavItem';
import { useUrlGenerator } from '../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../shared/providers/currentSite/useCurrentSite';
import { useUser } from '../../../shared/providers/user/useUser';
import { LoginModal } from '../../Login/LoginModal';

import { CommitmentsDropdown } from './Desktop/CommimentsDropdown/CommitmentsDropdown';
import { DestinationsDropdownContent } from './Desktop/Destinations/DestinationsDropdownContent';
import { WhereToGoDropdownContent } from './Desktop/WhereToGo/WhereToGoDropdownContent';
import { MobileMenu } from './Mobile/Menu/MobileMenu';
import style from './ResponsiveMenu.module.css';
import { UserDropdown } from './Shared/UserDropdown/UserDropdown';
import { DestinationDropDown, PrimaryNavValue, WhereToGoDropdown } from './types';

const DESTINATIONS_DROPDOWN_TRIGGER_ID = 'PrimaryNav-Destinations-Dropdown-Trigger';
const DESTINATIONS_DROPDOWN_CONTENT_ID = 'PrimaryNav-Destinations-Dropdown-Content';

const WHERE_TO_GO_DROPDOWN_TRIGGER_ID = 'PrimaryNav-WhereToGo-Dropdown-Trigger';
const WHERE_TO_GO_DROPDOWN_CONTENT_ID = 'PrimaryNav-WhereToGo-Dropdown-Content';

type Props = {
    destinationsDropdown?: DestinationDropDown;
    whereToGoDropdown?: WhereToGoDropdown;
    noMenu?: true;
    value: PrimaryNavValue;
    setValue: Dispatch<SetStateAction<PrimaryNavValue>>;
    previewMode?: boolean;
};

export function ResponsiveMenu({
    destinationsDropdown,
    whereToGoDropdown,
    noMenu,
    value,
    setValue,
    previewMode,
}: Props) {
    const { user } = useUser();
    const destinationsTriggerRef = useRef(null);
    const whereToGoTriggerRef = useRef(null);
    const isDestinationsDropdownOpen = value === 'DESTINATIONS';
    const isWhereToGoDropdownOpen = value === 'WHERE_TO_GO';
    const isUserDropdownOpen = value === 'USER';
    const isCommitmentsDropdownOpen = value === 'COMMITMENTS_DESKTOP';

    let dropdownTimeout: NodeJS.Timeout;

    function handleClickOutsideDropdown() {
        if (window.innerWidth >= 1200) {
            setValue(null);
        }
    }

    function clearDropdownTimeout() {
        clearTimeout(dropdownTimeout);
    }

    function setDropdownTimeout(callback: () => void) {
        dropdownTimeout = setTimeout(callback, 500);
    }

    if (noMenu) {
        return (
            <nav className={style.navNoMenu} role="navigation">
                <EvaneosLogoItem previewMode={previewMode} />
            </nav>
        );
    }

    return (
        <>
            <nav className={style.nav} role="navigation">
                <div className={style.navSide}>
                    <EvaneosLogoItem previewMode={previewMode} />

                    <DestinationsItem
                        isOpen={isDestinationsDropdownOpen}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('DESTINATIONS');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'DESTINATIONS' : null);
                        }}
                        ref={destinationsTriggerRef}
                    />
                    <WhereToGoItem
                        isOpen={isWhereToGoDropdownOpen}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('WHERE_TO_GO');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'WHERE_TO_GO' : null);
                        }}
                        ref={whereToGoTriggerRef}
                    />
                    <MagazineItem />
                    <CommitmentsItem
                        isOpen={isCommitmentsDropdownOpen}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('COMMITMENTS_DESKTOP');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onMouseEnterContent={() => {
                            clearDropdownTimeout();
                            setValue('COMMITMENTS_DESKTOP');
                        }}
                        onMouseLeaveContent={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'COMMITMENTS_DESKTOP' : null);
                        }}
                    />
                </div>

                <div className={style.navSide}>
                    <HelpCenterItem />
                    {user?.recommendationsIsEnabled ? <RecommendationsItem /> : null}
                    <UserAccountItem
                        isOpen={isUserDropdownOpen}
                        onOpenChange={(isOpen) => {
                            setValue(isOpen ? 'USER' : null);
                        }}
                    />
                    <MobileMenuButton
                        onClick={() => {
                            setValue(value === null ? 'MOBILE_MENU' : null);
                        }}
                    />
                </div>
            </nav>

            <MobileMenu
                destinationsDropdown={destinationsDropdown}
                navValue={value}
                onNavValueChange={(value) => {
                    setValue(value);
                }}
                whereToGoDropdown={whereToGoDropdown}
            />

            <div className={cx(style.dropdownContainer, style.hiddenMobile)}>
                {destinationsDropdown ? (
                    <DestinationsDropdownContent
                        aria-labelledby={DESTINATIONS_DROPDOWN_TRIGGER_ID}
                        contextContinent={destinationsDropdown.contextContinent}
                        continents={destinationsDropdown.continents}
                        id={DESTINATIONS_DROPDOWN_CONTENT_ID}
                        hidden={!isDestinationsDropdownOpen}
                        onClickOutside={handleClickOutsideDropdown}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('DESTINATIONS');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        triggerRef={destinationsTriggerRef}
                        world={destinationsDropdown.world}
                    />
                ) : null}
                {whereToGoDropdown ? (
                    <WhereToGoDropdownContent
                        aria-labelledby={WHERE_TO_GO_DROPDOWN_TRIGGER_ID}
                        hidden={!isWhereToGoDropdownOpen}
                        id={WHERE_TO_GO_DROPDOWN_CONTENT_ID}
                        onClickOutside={handleClickOutsideDropdown}
                        onMouseEnter={() => {
                            clearDropdownTimeout();
                            setValue('WHERE_TO_GO');
                        }}
                        onMouseLeave={() => {
                            setDropdownTimeout(() => {
                                setValue(null);
                            });
                        }}
                        travelTypes={whereToGoDropdown.travelTypes}
                        triggerRef={whereToGoTriggerRef}
                        what={whereToGoDropdown.what}
                        when={whereToGoDropdown.when}
                        who={whereToGoDropdown.who}
                    />
                ) : null}
            </div>
        </>
    );
}

function EvaneosLogoItem({ previewMode }: { previewMode?: boolean }) {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <a
            className={style.logo}
            href={generatePath('home')}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerLogoClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            }}
        >
            <EvaneosLogo height="100%" />
            {previewMode ? (
                <span className={cx('font-l-extrabold', style.previewModeText)}>Preview</span>
            ) : null}
        </a>
    );
}

const DestinationsItem = forwardRef<
    HTMLAnchorElement,
    {
        isOpen: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onOpenChange: (isOpen: boolean) => void;
    }
>(({ isOpen, onMouseEnter, onMouseLeave, onOpenChange }, ref) => {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    return (
        <NavItemLink
            iconTrailing={<IconChevronDown />}
            isDesktop
            aria-controls={DESTINATIONS_DROPDOWN_CONTENT_ID}
            aria-expanded={isOpen}
            className={style.hiddenMobile}
            href={generatePath('destination_list')}
            id={DESTINATIONS_DROPDOWN_TRIGGER_ID}
            onClick={(event: MouseEvent) => {
                event.preventDefault();
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerAllDestinationsClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
                onOpenChange(!isOpen);
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {formatMessage({
                id: 'global.header.globalNav.destinations.label',
            })}
        </NavItemLink>
    );
});

const WhereToGoItem = forwardRef<
    HTMLAnchorElement,
    {
        isOpen: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        onOpenChange: (isOpen: boolean) => void;
    }
>(({ isOpen, onMouseEnter, onMouseLeave, onOpenChange }, ref) => {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    return (
        <NavItemLink
            iconTrailing={<IconChevronDown />}
            isDesktop
            aria-controls={WHERE_TO_GO_DROPDOWN_CONTENT_ID}
            aria-expanded={isOpen}
            className={style.hiddenMobile}
            href={generatePath('where_to_go')}
            id={WHERE_TO_GO_DROPDOWN_TRIGGER_ID}
            onClick={(event: MouseEvent) => {
                event.preventDefault();
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerWheretogoClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
                onOpenChange(!isOpen);
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {formatMessage({
                id: 'global.header.globalNav.whereToGo.label',
            })}
        </NavItemLink>
    );
});

function MagazineItem() {
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    return (
        <NavItemLink
            isDesktop
            className={style.hiddenMobile}
            href={generatePath('magazine')}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerMagazineClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            }}
        >
            {formatMessage({
                id: 'global.header.globalNav.magazine.label',
            })}
        </NavItemLink>
    );
}

function CommitmentsItem({
    isOpen,
    onMouseEnter,
    onMouseEnterContent,
    onMouseLeave,
    onMouseLeaveContent,
    onOpenChange,
}: {
    isOpen: boolean;
    onMouseEnter: () => void;
    onMouseEnterContent: () => void;
    onMouseLeave: () => void;
    onMouseLeaveContent: () => void;
    onOpenChange: (isOpen: boolean) => void;
}) {
    return (
        <CommitmentsDropdown
            isOpen={isOpen}
            className={style.hiddenMobile}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseEnterContent={onMouseEnterContent}
            onMouseLeaveContent={onMouseLeaveContent}
            onOpenChange={onOpenChange}
        />
    );
}

function HelpCenterItem() {
    const { currentSite } = useCurrentSite();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    if (!currentSite.pages.faq.url) {
        return null;
    }

    return (
        <NavItemLink
            isDesktop
            className={style.hiddenMobile}
            href={currentSite.pages.faq.url}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerHelpCenterClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            }}
        >
            {formatMessage({
                id: 'global.header.globalNav.helpCenter.label',
            })}
        </NavItemLink>
    );
}

function RecommendationsItem() {
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    const { trackEvent } = useTrackEventAmplitude();

    return (
        <NavItemLink
            isDesktop
            className={style.hiddenMobile}
            href={generatePath('account_recommendations', {
                source: 'headbar',
            })}
            onClick={() => {
                trackEvent((ampli, defaultProperties) => {
                    ampli.headerRecommendationClicked({
                        ...defaultProperties,
                        block: 'header',
                    });
                });
            }}
        >
            {formatMessage({
                id: 'global.header.globalNav.recommendations.label',
            })}
        </NavItemLink>
    );
}

function UserAccountItem({
    isOpen,
    onOpenChange,
}: {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}) {
    const { user } = useUser();
    const loginModal = useModal();
    const { formatMessage } = useIntl();

    if (user) {
        return <UserDropdown user={user} isOpen={isOpen} onOpenChange={onOpenChange} />;
    }

    return (
        <>
            <NavItemButton
                iconLeading={<IconAccountCircle />}
                iconOnly="mobileOnly"
                isDesktop
                onClick={() => {
                    loginModal.open();
                }}
            >
                {formatMessage({
                    id: 'global.header.globalNav.account.label',
                })}
            </NavItemButton>
            {loginModal.isOpen ? <LoginModal context="default" {...loginModal} /> : null}
        </>
    );
}

function MobileMenuButton({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) {
    return (
        <NavItemButton
            className={style.burgerMenuButton}
            iconLeading={<IconBurger />}
            iconOnly
            onClick={onClick}
        />
    );
}
