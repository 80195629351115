import React from 'react';
import { useIntl } from 'react-intl';

import { useTrackEventAmplitude } from '../../../../../core/Tracking/Amplitude/Amplitude';
import { IconArrowRight } from '../../../../../design-system/components/icons/ArrowRight';
import {
    NavItemButton,
    NavItemLink,
} from '../../../../../design-system/components/nav/nav-item/NavItem';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';
import { useCurrentSite } from '../../../../../shared/providers/currentSite/useCurrentSite';
import { DestinationDropDown, PrimaryNavValue, WhereToGoDropdown } from '../../types';
import { MobileMenuList } from '../MenuList/MobileMenuList';
import { CommitmentsMobileView } from '../View/Commitments/CommitmentsMobileView';
import { DestinationsMobileView } from '../View/Destinations/DestinationsMobileView';
import { MobileView } from '../View/MobileView';
import { WhereToGoMobileView } from '../View/WhereToGo/WhereToGoMobileView';

import styles from './MobileMenu.module.css';

type Props = {
    destinationsDropdown?: DestinationDropDown;
    navValue: PrimaryNavValue;
    onNavValueChange: (view: PrimaryNavValue) => void;
    whereToGoDropdown?: WhereToGoDropdown;
};

export function MobileMenu({
    destinationsDropdown,
    navValue,
    onNavValueChange,
    whereToGoDropdown,
}: Props) {
    const { currentSite } = useCurrentSite();
    const { generatePath } = useUrlGenerator();
    const { trackEvent } = useTrackEventAmplitude();
    const { formatMessage } = useIntl();

    if (navValue === null || navValue === 'USER') {
        return null;
    }

    return (
        <div className={styles.container}>
            {navValue === 'MOBILE_MENU' ? (
                <MobileView>
                    <MobileMenuList>
                        <NavItemButton
                            iconTrailing={<IconArrowRight />}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.headerAllDestinationsClicked({
                                        ...defaultProperties,
                                        block: 'header',
                                    });
                                });
                                onNavValueChange('DESTINATIONS');
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.destinations.label',
                            })}
                        </NavItemButton>
                        <NavItemButton
                            iconTrailing={<IconArrowRight />}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.headerWheretogoClicked({
                                        ...defaultProperties,
                                        block: 'header',
                                    });
                                });
                                onNavValueChange('WHERE_TO_GO');
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.whereToGo.label',
                            })}
                        </NavItemButton>
                        <NavItemLink
                            href={generatePath('magazine')}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.headerMagazineClicked({
                                        ...defaultProperties,
                                        block: 'header',
                                    });
                                });
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.magazine.label',
                            })}
                        </NavItemLink>
                        <NavItemButton
                            iconTrailing={<IconArrowRight />}
                            onClick={() => {
                                trackEvent((ampli, defaultProperties) => {
                                    ampli.headerCommitmentsClicked({
                                        ...defaultProperties,
                                        block: 'header',
                                    });
                                });
                                onNavValueChange('COMMITMENTS_MOBILE');
                            }}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.commitments.label',
                            })}
                        </NavItemButton>
                        {currentSite.pages.faq.url ? (
                            <NavItemLink
                                href={currentSite.pages.faq.url}
                                onClick={() => {
                                    trackEvent((ampli, defaultProperties) => {
                                        ampli.headerHelpCenterClicked({
                                            ...defaultProperties,
                                            block: 'header',
                                        });
                                    });
                                }}
                            >
                                {formatMessage({
                                    id: 'global.header.globalNav.helpCenter.label',
                                })}
                            </NavItemLink>
                        ) : null}
                    </MobileMenuList>
                </MobileView>
            ) : null}

            {navValue === 'DESTINATIONS' && destinationsDropdown ? (
                <DestinationsMobileView
                    destinationsDropdown={destinationsDropdown}
                    onNavValueChange={onNavValueChange}
                />
            ) : null}

            {navValue === 'WHERE_TO_GO' && whereToGoDropdown ? (
                <WhereToGoMobileView
                    onNavValueChange={onNavValueChange}
                    whereToGoDropdown={whereToGoDropdown}
                />
            ) : null}

            {navValue === 'COMMITMENTS_MOBILE' ? (
                <CommitmentsMobileView onNavValueChange={onNavValueChange} />
            ) : null}
        </div>
    );
}
