import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useIntl } from 'react-intl';

import { Badge } from '../../../../../design-system/components/badge/Badge';
import { IconChevronDown } from '../../../../../design-system/components/icons/ChevronDown';
import { IconHeartFilled } from '../../../../../design-system/components/icons/HeartFilled';
import { IconLuggage } from '../../../../../design-system/components/icons/Luggage';
import { IconSettings } from '../../../../../design-system/components/icons/Settings';
import { IconSignOut } from '../../../../../design-system/components/icons/SignOut';
import { IconStars } from '../../../../../design-system/components/icons/Stars';
import { MenuItem } from '../../../../../design-system/components/nav/menu-item/MenuItem';
import { NavItemButton } from '../../../../../design-system/components/nav/nav-item/NavItem';
import { User } from '../../../../../resources/user/domain/types/User';
import { useUrlGenerator } from '../../../../../shared/hooks/useUrlGenerator';

import styles from './UserDropdown.module.css';

interface Props {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    user: User;
}

export function UserDropdown({ user, isOpen, onOpenChange }: Props) {
    const { formatMessage } = useIntl();
    const { generatePath } = useUrlGenerator();

    return (
        <DropdownMenu.Root
            modal={false}
            open={isOpen}
            onOpenChange={(isOpen) => {
                onOpenChange(isOpen);
            }}
        >
            <DropdownMenu.Trigger asChild>
                <NavItemButton
                    className="amp-mask"
                    iconLeading={
                        <Badge bgColor="custom-brand-red" Content={user.firstName || 'Evaneos'} />
                    }
                    iconTrailing={<IconChevronDown />}
                    iconOnly="mobileOnly"
                    isDesktop
                >
                    {user.firstName ||
                        formatMessage({
                            id: 'global.header.globalNav.account.label',
                        })}
                </NavItemButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content side="bottom" align="end" className={styles.menu}>
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_trips')}
                            Icon={IconLuggage}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.trips',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    {user.recommendationsIsEnabled ? (
                        <DropdownMenu.Item asChild>
                            <MenuItem
                                className={styles.menuListItem}
                                href={generatePath('account_recommendations', {
                                    source: 'navigation_bar',
                                })}
                                Icon={IconStars}
                            >
                                {formatMessage({
                                    id: 'global.header.globalNav.recommendations.label',
                                })}
                            </MenuItem>
                        </DropdownMenu.Item>
                    ) : null}
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_wishlist')}
                            Icon={IconHeartFilled}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.wishes',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('account_settings')}
                            Icon={IconSettings}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.settings',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator asChild>
                        <hr className={styles.hr} />
                    </DropdownMenu.Separator>
                    <DropdownMenu.Item asChild>
                        <MenuItem
                            className={styles.menuListItem}
                            href={generatePath('logout')}
                            Icon={IconSignOut}
                        >
                            {formatMessage({
                                id: 'global.header.globalNav.account.logout',
                            })}
                        </MenuItem>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}
